import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import './publication_chart.scss';
import { getMinMax } from '../../helpers/getMinMax';
import { fillingPeriodArray } from '../../helpers/fillingPeriodArray';

import { CustomTooltip } from '../UI/CustomTooltip/CustomTooltip';
import { SelectingComp } from '../UI/SelectingComp/SelectingComp';
import { HorizontalList } from '../UI/HorizontalList/HorizontalList';
import { getTooltipPublicationFields } from '../UI/CustomTooltip/tooltipData';
import { LoadingSpinner } from '../UI/LoadingComponent/LoadingComponent';
import { MedicineSwitchComp } from '../UI/SwitchComp/MedicineSwitchComp';

const selectOptions = ['Total number', 'By quartile'];

/*
This component renders a publication chart that displays the number of publications.
@param {object} publicationData - The data used to generate the chart.
@param {boolean} loadingData - A boolean that indicates if the data is still loading.
@returns {JSX.Element} - Returns the JSX for the component.
*/
export const PublicationChart = ({ publicationData, loadingData }) => {
  const [showOneDrug, setShowOneDrug] = useState(true);
  const data = publicationData || [];

  // Medicines data
  const MedicinesData =
    data.Medicines?.map((med) => ({
      Year: med.First_Clinical_trials_date,
      Drug_Name: med.Generic_Name,
      Drugs: 1,
    })) ?? [];

  const displayedMedicines = showOneDrug
    ? MedicinesData.filter((m) => m.Year !== null).reduce((a, b) => {
        if (!a) return b;
        if (!b) return a;
        return a.Year < b.Year ? a : b;
      }, null) ?? []
    : MedicinesData.filter((m) => m.Year !== null) ?? [];

  // Paper data
  const paperData =
    (data.Protein_paper_trends &&
      data.Protein_paper_trends.map((trend) => ({
        Year: trend.Year,
        Amount: trend.Data.reduce((sum, amount) => sum + parseInt(amount.Amount), 0),
        Data: trend.Data,
      }))) ||
    [];

  const transformedPaperData = paperData.map((item) => {
    const newItem = {
      Year: item.Year,
      Amount: item.Amount,
      'N/A': null,
      Q1: null,
      Q2: null,
      Q3: null,
      Q4: null,
    };
    item.Data.forEach((dataItem) => {
      switch (dataItem.Quartile) {
        case 0:
          newItem['N/A'] = dataItem.Amount;
          break;
        case 1:
          newItem.Q1 = dataItem.Amount;
          break;
        case 2:
          newItem.Q2 = dataItem.Amount;
          break;
        case 3:
          newItem.Q3 = dataItem.Amount;
          break;
        case 4:
          newItem.Q4 = dataItem.Amount;
          break;
        default:
          break;
      }
    });
    return newItem;
  });

  const paperArrayWithMeds = transformedPaperData.concat(displayedMedicines);
  const [minPaperYear, maxPaperYear] = getMinMax(paperArrayWithMeds, 'Year');
  const fillingPaperArray = fillingPeriodArray(minPaperYear, maxPaperYear, paperArrayWithMeds);

  //patent data
  const patentData = data.Protein_patent_trends || [];
  const patentArrayWithMeds = patentData.concat(displayedMedicines);
  const [minPatentYear, maxPatentYear] = getMinMax(patentArrayWithMeds, 'Year');
  const fillingPatentArray = fillingPeriodArray(minPatentYear, maxPatentYear, patentArrayWithMeds);

  //buttons
  const [isActive, setIsActive] = useState(false);
  const [activeButton, setActiveButton] = useState(0);

  const [selectValue, setSelectValue] = useState(selectOptions[0]);

  const buttons = [
    { key: 1, name: 'Patents', active: isActive },
    { key: 2, name: 'Articles', active: isActive },
  ];

  //Function that is called when a button is clicked. It sets the active button
  const handleOnClick = (i) => {
    if (i === activeButton) return;

    let newIsActive = {};
    if (i === 0) {
      for (let j = 0; j < buttons.length; j++) {
        newIsActive[j] = true;
      }
    } else {
      for (let j = 0; j < buttons.length; j++) {
        newIsActive[j] = j === i;
      }
    }
    setIsActive(newIsActive);
    setActiveButton(i);
  };

  const maxPatentAmount =
    fillingPatentArray.length > 0
      ? Math.max(...fillingPatentArray.map((entry) => entry.Amount))
      : 0;
  const maxPaperAmount =
    fillingPaperArray.length > 0 ? Math.max(...fillingPaperArray.map((entry) => entry.Amount)) : 0;

  return (
    <span className='global_chart_container' style={{ gap: 7 }}>
      <span className='chart_publication_title'>
        <p className='chart_header'>Number of publications</p>
        <span className='chart_publication_data_container'>
          <HorizontalList buttons={buttons} activeButton={activeButton} onClick={handleOnClick} />
          <span className='right_data_container'>
            <MedicineSwitchComp show={showOneDrug} setShow={setShowOneDrug} data={MedicinesData} />

            {activeButton === 0 ? null : (
              <SelectingComp
                selectValue={selectValue}
                selectDefaultValue={selectValue}
                selectStyles={{ width: '180px' }}
                onFilterValue={setSelectValue}
                selectOptions={selectOptions}
              />
            )}
          </span>
        </span>
      </span>
      <div style={{ position: 'relative' }}>
        {loadingData && <LoadingSpinner />}
        <ResponsiveContainer width='100%' height={600}>
          <BarChart
            width={500}
            height={300}
            data={activeButton === 0 ? fillingPatentArray : fillingPaperArray}
            margin={{
              top: 10,
              right: 0,
              left: -24,
              bottom: 0,
            }}
            className={activeButton === 1 && selectValue === 'By quartile' && 'my_bar_rechart'}
            barSize={16}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='Year'
              tick={{ fontSize: 12 }}
              interval={
                (activeButton === 0 && fillingPatentArray.length < 23) ||
                (activeButton === 1 && fillingPaperArray.length < 23)
                  ? 0
                  : ''
              }
            />
            <YAxis
              tick={{
                fontSize:
                  (activeButton === 0 && maxPatentAmount >= 1000) ||
                  (activeButton === 1 && maxPaperAmount >= 1000)
                    ? 12
                    : undefined,
              }}
              width={
                (activeButton === 0 && maxPatentAmount >= 1000) ||
                (activeButton === 1 && maxPaperAmount >= 1000)
                  ? 68
                  : undefined
              }
            />

            <Tooltip
              offset={20}
              wrapperStyle={{ outline: 'none' }}
              content={(props) => {
                const { payload } = props;
                if (payload && payload.length > 0) {
                  const selectedFields = getTooltipPublicationFields(
                    activeButton === 0
                      ? 1
                      : activeButton === 1 && selectValue === 'Total number'
                      ? 2
                      : activeButton === 1 && selectValue === 'By quartile'
                      ? 3
                      : 4,
                    payload,
                  );
                  return <CustomTooltip isPublicationChart data={selectedFields} />;
                }
                return null;
              }}
            />
            <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
            {activeButton === 0 ? (
              <>
                <Bar dataKey='Amount' fill='#605DEC' barSize={16} />
                <Bar dataKey='Drugs' fill='#BAB3BC' minPointSize={521} barSize={2} />
              </>
            ) : (
              <>
                {selectValue === 'Total number' ? (
                  <>
                    <Bar dataKey='Amount' fill='#605DEC' barSize={16} />
                    <Bar dataKey='Drugs' fill='#BAB3BC' minPointSize={521} barSize={2} />
                  </>
                ) : (
                  <>
                    <Bar dataKey='N/A' fill='#E7E7E7' barSize={5} />
                    <Bar dataKey='Q1' fill='#605DEC' barSize={5} />
                    <Bar dataKey='Q2' fill='#44BCFF' barSize={5} />
                    <Bar dataKey='Q3' fill='#CC33FF' barSize={5} />
                    <Bar dataKey='Q4' fill='#FFC420' barSize={5} />
                    <Bar dataKey='Drugs' fill='#BAB3BC' minPointSize={521} barSize={2} />
                  </>
                )}
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* <Test /> */}
    </span>
  );
};
