export const getButtonPadding = (isGlobalSearch, aggregation) => {
  const len = aggregation ? aggregation.toString().length : 0;
  if (isGlobalSearch && len > 4) {
    return '0 36px 4px 16px';
  } else if (isGlobalSearch && len > 3) {
    return '0 30px 4px 16px';
  } else if (isGlobalSearch && len > 2) {
    return '0 24px 4px 16px';
  } else if (isGlobalSearch && len > 1) {
    return '0 18px 4px 16px';
  } else {
    return '0 12px 4px 12px';
  }
};
