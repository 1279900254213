import React from 'react';
import './result_card.scss';

import { formatFromDateTimeToString } from '../../helpers/formatDates';

/*
Get the CSS class name based on the tag type
@param {String} tag - The tag type
@returns {String} - The corresponding CSS class name
*/
const getTagClass = (tag) => {
  switch (tag) {
    case 'Patent':
      return 'card_tag card_tag_patent';
    case 'Article':
      return 'card_tag card_tag_article';
    case 'Financing':
      return 'card_tag card_tag_financing';
    case 'Target':
      return 'card_tag card_tag_target';
    default:
      return 'card_tag card_tag_undef';
  }
};

/*
A component that renders a result card with the given data
@param {Object} data - The data to be displayed in the result card
@param {Function} onDataClick - The callback function to be called when the card is clicked
@param {Object} selectedData - The data that is currently selected
@returns {JSX.Element} - A JSX element representing the result card
*/

export const ResultCard = ({ data, onDataClick, selectedData }) => {
  return (
    <div
      className={`card_container ${
        selectedData && data.id === selectedData.id ? 'active_card_container' : ''
      }
      `}
      onClick={() => onDataClick(data)}
    >
      <span
        className={getTagClass(data.tag)}
        style={{
          opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
        }}
      >
        {data.tag || '-'}
      </span>
      <span
        className='card_title'
        style={{
          opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
        }}
      >
        {data.title || '-'}
      </span>
      {data.tag === 'Patent' ? (
        <span
          className='card_primary_id'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          {data.applicationNumber || '-'}
        </span>
      ) : data.tag === 'Article' ? (
        <span
          className='card_primary_id'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          {data.DOI || '-'}
        </span>
      ) : data.tag === 'Target' ? (
        <span
          className='card_primary_id'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          {data.uniprotId || '-'}
        </span>
      ) : data.tag === 'Financing' ? (
        <span
          className='card_primary_id'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          {formatFromDateTimeToString(data.publicationDate) || '-'}
        </span>
      ) : (
        '-'
      )}
      {data.tag === 'Patent' || data.tag === 'Article' ? (
        <span
          className='card_abstract'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          {data.abstract || '-'}
        </span>
      ) : data.tag === 'Target' ? (
        <span
          className='card_target_info'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          <span>Gene Symbol: {data.geneSymbol || '-'}</span>
          <span>Patents: {data.patentsAmount || 'none'}</span>
          <span>Articles: {data.articlesAmount || 'none'}</span>
          <span>Financing: {data.financingAmount || 'none'}</span>
        </span>
      ) : data.tag === 'Financing' ? (
        <span
          className='card_abstract'
          style={{
            opacity: selectedData && data.id === selectedData.id ? 0.5 : 1,
          }}
        >
          {data.fullText || '-'}
        </span>
      ) : (
        '-'
      )}
    </div>
  );
};
