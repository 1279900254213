import React, { useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { Context } from '../../../helpers/context';

import { DropdownComp } from '../DropdownComp/DropdownComp';

import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
// import { ReactComponent as UserIcon } from '../../../assets/icons/userMenuIcons/user.svg';
import { ReactComponent as OutIcon } from '../../../assets/icons/userMenuIcons/out.svg';
import { ReactComponent as SubsIcon } from '../../../assets/icons/userMenuIcons/subs.svg';
import './navbar.scss';

export const Navbar = () => {
  // Get user and logout function from Context
  const { logout, user } = useContext(Context);

  // Get navigate function and location from react-router-dom
  const navigate = useNavigate();
  const location = useLocation();

  // Handle menu item clicks
  const handleMenuClick = (e) => {
    if (e.key === '2') {
      navigate(`/my_subscriptions`);
    } else if (e.key === '3') {
      logout();
    }
  };

  // Menu items for the dropdown
  const items = [
    // {
    //   key: '1',
    //   label: 'User account',
    //   icon: <UserIcon />,
    // },
    {
      key: '2',
      label: 'My Subscriptions',
      icon: <SubsIcon />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: 'Sign out',
      icon: <OutIcon />,
    },
  ];

  // Props for the dropdown menu
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  // Render the navbar
  return (
    <nav className={location.pathname !== '/login' ? 'navbar' : 'navbar_none'}>
      <span className='navbar_container'>
        <span className='navbar_logo-menu'>
          {/* Render the logo and menu */}
          <Logo onClick={() => navigate(`/trends`)} style={{ cursor: 'pointer' }} />
          <ul>
            <li>
              {/* Render the trends link */}
              <NavLink to='/trends' className={({ isActive }) => (isActive ? 'active' : '')}>
                Target trends
              </NavLink>
            </li>
            <li>
              {/* Render the global search link */}
              <NavLink
                to={
                  localStorage.resultValue && localStorage.resultValue.length > 0
                    ? `global_search/${localStorage.resultValue}`
                    : '/global_search'
                }
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Global search
              </NavLink>
            </li>
          </ul>
        </span>
        {/* Render the user dropdown */}
        <DropdownComp menuProps={menuProps} placement='bottomRight'>
          <Space style={{ cursor: 'pointer' }}>
            {/* Render the user's name */}
            {user && user.name}
            <DownOutlined />
          </Space>
        </DropdownComp>
      </span>
    </nav>
  );
};
