import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FormInput } from '../../components/UI/FormInput/FormInput';
import { ButtonComp } from '../../components/UI/ButtonComp/ButtonComp';
// import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { Context } from '../../helpers/context';
import { LOGIN_URL } from '../../helpers/config';
import './login_page.scss';
import '../../assets/scss/page_global_styles.scss';

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorText, setRequestErrorText] = useState('');

  const { login } = useContext(Context);

  // Function to handle email input focus
  const handleEmailFocus = () => {
    setEmailError(false);
    setRequestError(false);
  };

  // Function to handle password input focus
  const handlePasswordFocus = () => {
    setPasswordError(false);
    setRequestError(false);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email && password) {
      try {
        const response = await axios.post(LOGIN_URL, {
          username: email,
          password: password,
        });
        if (response.status === 200 && response.data) {
          login(response.data);
          navigate(localStorage.getItem('prevPath') ?? '/trends');
        } else {
          setRequestError(true);
          setRequestErrorText(
            response.data.detail === 'User not found'
              ? 'Incorrect login or password'
              : response.data.detail,
          );
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setEmailError(!email);
      setPasswordError(!password);
    }
  };

  return (
    <div className='auth_page_container'>
      {/* <Logo style={{ marginBottom: 16 }} /> */}
      <form onSubmit={handleSubmit} className='form'>
        <span className='form_name'>Login</span>
        <span className='body_section'>
          <p className='section_title'>Email / Username</p>
          <FormInput
            value={email}
            placeholder='name@gmail.com'
            onChange={(event) => setEmail(event.target.value)}
            onFocus={handleEmailFocus}
            className='form_input'
          />
          {emailError && (
            <div className='error_text_container'>
              <span className='error_text'>Please enter your email address</span>
            </div>
          )}
        </span>
        <span className='body_section'>
          <p className='section_title'>Password</p>
          <FormInput
            isPassword
            value={password}
            placeholder='your password'
            onChange={(event) => setPassword(event.target.value)}
            onFocus={handlePasswordFocus}
            className='form_input'
          />
          {passwordError && (
            <div className='error_text_container'>
              <span className='error_text'>Please enter your password</span>
            </div>
          )}
        </span>

        <span className='form_btn_container'>
          <ButtonComp buttonClassName='btn_antd form_button' htmlType='submit' buttonSize='large'>
            Login
          </ButtonComp>
          {requestError && (
            <div className='response_error_text_container'>
              <span className='response_error_text'>{requestErrorText}</span>
            </div>
          )}
        </span>
      </form>
    </div>
  );
};

export default Login;
