import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './description.scss';

/*
Function to render the right array item component, which displays an array of items with the option to collapse and show more.
@param title {String} The title of the array section, if applicable
@param arrayItem {Array} The array of items to display
@param isGlobalSearch {Boolean} Flag indicating if the component is being used in a global search context
@param collapse {Boolean} Flag indicating if the component should have the option to collapse and show more items
@returns {JSX} Returns the JSX to render the component
*/
export const RenderRightArrayItem = ({ title, arrayItem, isGlobalSearch, collapse }) => {
  const [showAllElements, setShowAllElements] = useState(collapse ? false : true);

  const displayItems = showAllElements ? arrayItem : arrayItem?.slice(0, 6);

  const handleShowAllElements = () => {
    setShowAllElements(!showAllElements);
  };

  return (
    <div className='info_section'>
      {title && <span className='info_section_title'>{title}</span>}
      <span className='info_section_text'>
        {arrayItem && arrayItem?.length > 0 ? (
          displayItems.map((item, index) => (
            <div className={title || isGlobalSearch ? 'author_item' : 'name_item'} key={index}>
              {
                // .toLocaleString('en-US', {
                //     style: 'currency',
                //     currency: 'USD',
                //     minimumFractionDigits: 2,
                //     maximumFractionDigits: 2,
                //     useGrouping: true,
                //     groupingSeparator: ' ',
                //   })
                item
              }
            </div>
          ))
        ) : (
          <div>-</div>
        )}
      </span>
      {collapse && arrayItem?.length > 6 && (
        <span
          className='collapse_array'
          style={{ marginTop: isGlobalSearch ? 0 : 12 }}
          onClick={handleShowAllElements}
        >
          {showAllElements ? (
            <span>
              Hide <UpOutlined style={{ fontSize: 11 }} />
            </span>
          ) : (
            <span>
              Show more <DownOutlined style={{ fontSize: 11 }} />
            </span>
          )}
        </span>
      )}
    </div>
  );
};
