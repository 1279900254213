import { formatRevertDashDate } from '../../helpers/formatDates';
import { getProteinName } from '../../helpers/queryFunctions';

/*
This function takes in an array of trendsData and returns an array of relevant targets data with specific properties.
@param trendsData {Array} The array of trends data to be processed
@returns {Array} The processed data in the form of an array of objects
*/
export const getTableRelevantTargetsData = (trendsData) => {
  let dataRelevantTargetsTable = [];
  if (trendsData) {
    for (let i = 0; i < trendsData.length; ++i) {
      const trendIndex = trendsData[i].Relevance_index + '|' + trendsData[i].Last_change;
      const diseases = [
        ...new Set(
          trendsData[i].Protein_data &&
            trendsData[i].Protein_data.Diseases &&
            trendsData[i].Protein_data.Diseases.map((item) => item.Disease_name),
        ),
      ];
      const therapeuticCategories = [
        ...new Set(
          trendsData[i].Protein_data &&
            trendsData[i].Protein_data.Diseases &&
            trendsData[i].Protein_data.Diseases.flatMap((item) => item.Therapeutic_categories).map(
              (item) => item.Therapeutic_category_name,
            ),
        ),
      ];
      let targetName = '-';
      const proteinData = trendsData[i]?.Protein_data?.Description;
      if (proteinData) {
        targetName = getProteinName(proteinData);
      }
      dataRelevantTargetsTable.push({
        key: trendsData[i].Protein_id,
        trendIndex: trendIndex,
        uniprotID: trendsData[i].Protein_data.Uniprot_id,
        name: targetName,
        therapyCat: therapeuticCategories.length,
        disease: diseases.length,
        lastMention: formatRevertDashDate(trendsData[i].Last_mention && trendsData[i].Last_mention),
        childData: {
          geneSymb: trendsData[i].Protein_data.Gene.Gene_name,
          // IDGFamily: '???',
          papers: trendsData[i].Paper_total_number,
          patents: trendsData[i].Patent_total_number,
          // financing: trendsData[i].Financing_total_number,
          categories: therapeuticCategories,
          diseases: diseases,
          lastChange: trendsData[i].Last_change,
        },
      });
    }
  }
  return dataRelevantTargetsTable;
};

/*
This function takes in an array of newData and returns an array of new data with specific properties.
@param newData {Array} The array of new data to be processed
@returns {Array} The processed data in the form of an array of objects
*/
export const getTableNewData = (newData) => {
  let dataNewTable = [];
  if (newData) {
    for (let i = 0; i < newData.length; ++i) {
      const trendIndex = newData[i].Relevance_index + '|' + newData[i].Last_change;
      const diseases = [
        ...new Set(
          newData[i].Protein_data &&
            newData[i].Protein_data.Diseases &&
            newData[i].Protein_data.Diseases.map((item) => item.Disease_name),
        ),
      ];
      const therapeuticCategories = [
        ...new Set(
          newData[i].Protein_data &&
            newData[i].Protein_data.Diseases &&
            newData[i].Protein_data.Diseases.flatMap((item) => item.Therapeutic_categories).map(
              (item) => item.Therapeutic_category_name,
            ),
        ),
      ];
      let newsName = '-';
      const proteinData = newData[i]?.Protein_data?.Description;
      if (proteinData) {
        newsName = getProteinName(proteinData);
      }
      dataNewTable.push({
        key: newData[i].Protein_id,
        trendIndex: trendIndex,
        uniprotID: newData[i].Protein_data.Uniprot_id,
        name: newsName,
        therapyCat: therapeuticCategories.length,
        disease: diseases.length,
        lastMention: formatRevertDashDate(newData[i].Last_mention && newData[i].Last_mention),
        childData: {
          geneSymb: newData[i].Protein_data.Gene.Gene_name,
          // IDGFamily: '???',
          papers: newData[i].Paper_total_number,
          patents: newData[i].Patent_total_number,
          // financing: newData[i].Financing_total_number,
          categories: therapeuticCategories,
          diseases: diseases,
          lastChange: newData[i].Last_change,
        },
      });
    }
  }
  return dataNewTable;
};
