import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  getTargetID,
  getTargetRoute,
  getTargetRelatedDiseasesRoute,
  getTargetIndexPeriodRoute,
  getTargetIndexDiseaseRoute,
  getTargetIndexCategoryRoute,
  getTargetPublicationRoute,
  getTargetPublicationDiseaseRoute,
  getTargetPublicationCategoryRoute,
  getTargetPatentsRoute,
  getTargetPatentsDiseaseRoute,
  getTargetPatentsCategoryRoute,
  getTargetPapersRoute,
  getTargetPapersDiseaseRoute,
  getTargetPapersCategoryRoute,
  getTargetFinancingRoute,
  getTargetFinancingDiseaseRoute,
  getTargetFinancingCategoryRoute,
} from './targetRoutes';

import { VALID_TOKEN_URL } from '../../helpers/config';
import { debounceFunction } from '../../helpers/debounce';
import { authHeader, refreshFunction } from '../../helpers/authFunctions';
import { fetchTableData, checkFoundData } from '../../helpers/queryFunctions';
import { Context } from '../../helpers/context';

import { IndexChart } from '../../components/IndexAreaChart/IndexChart';
import { PublicationChart } from '../../components/PublicationBarChart/PublicationChart';
import { OtherDataTable } from '../../components/OtherDataTable/OtherDataTable';
import FilteringComp from '../../components/FilteringComp/FilteringComp';

import { PageTitle } from '../../components/UI/PageTitle/PageTitle';
import { TransitionExpandComp } from '../../components/UI/TransitionExpand/TransitionExpandComp';
import { LoadingSpinner } from '../../components/UI/LoadingComponent/LoadingComponent';

import { ReactComponent as EmptyBoxIcon } from '../../assets/icons/emptyBox.svg';
import '../../assets/scss/page_global_styles.scss';
import './target_page.scss';

const Target = () => {
  // Context and params
  const { refresh, refreshToken, logout } = useContext(Context);
  const { targetUniprotId } = useParams();

  // State variables
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [targetData, setTargetData] = useState();
  const [targetErr, setTargetErr] = useState('');

  const [targetId, setTargetId] = useState();
  const [relatedDiseasesData, setRelatedDiseasesData] = useState();
  const [targetIndexData, setTargetIndexData] = useState();
  const [targetPublicationData, setTargetPublicationData] = useState();
  const [targetPatentsData, setTargetPatentsData] = useState();
  const [targetPapersData, setTargetPapersData] = useState();
  const [targetFinancingData, setTargetFinancingData] = useState();

  const [loadingData, setLoadingData] = useState(false);
  const [loadingIndexChartData, setLoadingIndexChartData] = useState(false);
  const [loadingPublicationChartData, setLoadingPublicationChartData] = useState(false);
  const [loadingPatentsTableData, setLoadingPatentsTableData] = useState(false);
  const [loadingPapersTableData, setLoadingPapersTableData] = useState(false);
  const [loadingFinancingTableData, setLoadingFinancingTableData] = useState(false);

  const [selectedDisease, setSelectedDisease] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const [indexPeriodFrom, setIndexPeriodFrom] = useState('');
  const [indexPeriodTo, setIndexPeriodTo] = useState('');

  const [activeTableButton, setActiveTableButton] = useState(0);

  // Function to update state variables when the date range changes (date is important props, which can't be removed)
  const onRangeChange = (date, dateStrings) => {
    setIndexPeriodFrom(dateStrings[0]);
    setIndexPeriodTo(dateStrings[1]);
  };

  const [patentsPageCap, setPatentsPageCap] = useState(1);
  const [papersPageCap, setPapersPageCap] = useState(1);
  const [financingPageCap, setFinancingPageCap] = useState(1);

  const [patentsTotalCount, setPatentsTotalCount] = useState();
  const [papersTotalCount, setPapersTotalCount] = useState();
  const [financingTotalCount, setFinancingTotalCount] = useState();

  const [searchTableData, setSearchTableData] = useState('');

  // Debounced function to handle search bar input
  const handleOnSearchChange = debounceFunction(async (e) => {
    setSearchTableData(e.target.value);
  }, 700);

  //routes with params on this page
  const routes = {
    targetIdRoute: getTargetID(targetUniprotId),
    targetRoute: getTargetRoute(targetId),
    targetRelatedDiseasesRoute: getTargetRelatedDiseasesRoute(targetId),
    targetIndexPeriodRoute: getTargetIndexPeriodRoute(targetId, indexPeriodFrom, indexPeriodTo),
    targetIndexDiseaseRoute: getTargetIndexDiseaseRoute(
      targetId,
      indexPeriodFrom,
      indexPeriodTo,
      selectedDisease,
    ),
    targetIndexCategoryRoute: getTargetIndexCategoryRoute(
      targetId,
      indexPeriodFrom,
      indexPeriodTo,
      selectedCategory,
    ),
    targetPublicationRoute: getTargetPublicationRoute(targetId),
    targetPublicationDiseaseRoute: getTargetPublicationDiseaseRoute(targetId, selectedDisease),
    targetPublicationCategoryRoute: getTargetPublicationCategoryRoute(targetId, selectedCategory),
    targetPatentsRoute: getTargetPatentsRoute(
      targetId,
      patentsPageCap,
      searchTableData,
      selectedDisease,
    ),
    targetPatentsDiseaseRoute: getTargetPatentsDiseaseRoute(
      targetId,
      patentsPageCap,
      searchTableData,
      selectedDisease,
    ),
    targetPatentsCategoryRoute: getTargetPatentsCategoryRoute(
      targetId,
      patentsPageCap,
      searchTableData,
      selectedCategory,
    ),
    targetPapersRoute: getTargetPapersRoute(
      targetId,
      papersPageCap,
      searchTableData,
      selectedDisease,
    ),
    targetPapersDiseaseRoute: getTargetPapersDiseaseRoute(
      targetId,
      papersPageCap,
      searchTableData,
      selectedDisease,
    ),
    targetPapersCategoryRoute: getTargetPapersCategoryRoute(
      targetId,
      papersPageCap,
      searchTableData,
      selectedCategory,
    ),
    targetFinancingRoute: getTargetFinancingRoute(
      targetId,
      financingPageCap,
      searchTableData,
      selectedDisease,
    ),
    targetFinancingDiseaseRoute: getTargetFinancingDiseaseRoute(
      targetId,
      financingPageCap,
      searchTableData,
      selectedDisease,
    ),
    targetFinancingCategoryRoute: getTargetFinancingCategoryRoute(
      targetId,
      financingPageCap,
      searchTableData,
      selectedCategory,
    ),
  };

  // Crafting routes
  const indexRoute =
    routes[
      selectedDisease
        ? 'targetIndexDiseaseRoute'
        : selectedCategory
        ? 'targetIndexCategoryRoute'
        : 'targetIndexPeriodRoute'
    ];
  const publicationRoute =
    routes[
      selectedDisease
        ? 'targetPublicationDiseaseRoute'
        : selectedCategory
        ? 'targetPublicationCategoryRoute'
        : 'targetPublicationRoute'
    ];
  const patentsRoute =
    routes[
      selectedDisease
        ? 'targetPatentsDiseaseRoute'
        : selectedCategory
        ? 'targetPatentsCategoryRoute'
        : 'targetPatentsRoute'
    ];
  const papersRoute =
    routes[
      selectedDisease
        ? 'targetPapersDiseaseRoute'
        : selectedCategory
        ? 'targetPapersCategoryRoute'
        : 'targetPapersRoute'
    ];
  const financingRoute =
    routes[
      selectedDisease
        ? 'targetFinancingDiseaseRoute'
        : selectedCategory
        ? 'targetFinancingCategoryRoute'
        : 'targetFinancingRoute'
    ];

  //Fetch initial data for target page
  useEffect(() => {
    setLoadingData(true);
    setLoadingIndexChartData(true);
    setLoadingPublicationChartData(true);
    setLoadingPatentsTableData(true);
    setLoadingPapersTableData(true);
    setLoadingFinancingTableData(true);
    const fetchData = async () => {
      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        const response = await axios.get(routes.targetIdRoute, { headers: authHeader() });
        setTargetErr(response.data.detail);
        setTargetId(response.data.ID);
      } catch (error) {
        console.error(error);
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      }
    };
    fetchData();
  }, []);

  //Fetch data when target ID changes
  useEffect(() => {
    const fetchData = async () => {
      if (targetId) {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          axios
            .get(routes.targetRoute, {
              headers: authHeader(),
            })
            .then((targetRes) => {
              setTargetData(checkFoundData(targetRes.data));
              return axios.get(routes.targetRelatedDiseasesRoute, {
                headers: authHeader(),
              });
            })
            .then((diseasesRes) => {
              setRelatedDiseasesData(checkFoundData(diseasesRes.data));
              setLoadingData(false);
              return axios.get(routes.targetIndexPeriodRoute, {
                headers: authHeader(),
              });
            })
            .then((indexRes) => {
              setTargetIndexData(checkFoundData(indexRes.data));
              setLoadingIndexChartData(false);
              return axios.get(routes.targetPublicationRoute, {
                headers: authHeader(),
              });
            })
            .then((publicationRes) => {
              setTargetPublicationData(checkFoundData(publicationRes.data));
              setLoadingPublicationChartData(false);
              return axios.get(routes.targetPatentsRoute, {
                headers: authHeader(),
              });
            })
            .then((patentsRes) => {
              setTargetPatentsData(checkFoundData(patentsRes.data));
              setPatentsTotalCount(checkFoundData(patentsRes.data.Total_count));
              setLoadingPatentsTableData(false);
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingData(false);
        }
      }
    };
    fetchData();
  }, [targetId]);

  // Fetch index data when index period changes
  useEffect(() => {
    setLoadingIndexChartData(true);
    const fetchData = async () => {
      if (targetId) {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          axios
            .get(routes.targetIndexPeriodRoute, {
              headers: authHeader(),
            })
            .then((response) => {
              setTargetIndexData(response.data);
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              setLoadingIndexChartData(false);
            });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        }
      }
    };
    fetchData();
  }, [indexPeriodFrom, indexPeriodTo]);

  //Handle pagination page change for tables
  const handlePaginationPageChange = (page) => {
    if (activeTableButton === 0) {
      setPatentsPageCap(page);
    }
    if (activeTableButton === 1) {
      setPapersPageCap(page);
    }
    if (activeTableButton === 2) {
      setFinancingPageCap(page);
    }
  };

  /*
  Handles button click on table navigation
  @param index {Number} Index of the clicked button
  @returns {void} 
  */
  const handleTableButtonClick = (index) => {
    setActiveTableButton(index);
  };

  //State variables for checking route changes
  const [checkPatentsRoute, setCheckPatentsRoute] = useState(patentsRoute);
  const [checkPapersRoute, setCheckPapersRoute] = useState(papersRoute);
  const [checkFinancingRoute, setCheckFinancingRoute] = useState(financingRoute);

  //Fetches data for the selected table button
  useEffect(() => {
    const fetchData = async () => {
      if (targetId) {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          if (activeTableButton === 0 && isFirstLoad) {
            setIsFirstLoad(false);
          } else if (activeTableButton === 0) {
            fetchTableData(
              patentsRoute,
              checkPatentsRoute,
              targetPatentsData,
              setTargetPatentsData,
              setCheckPatentsRoute,
              setLoadingPatentsTableData,
              authHeader,
              setPatentsTotalCount,
            );
          } else if (activeTableButton === 1) {
            fetchTableData(
              papersRoute,
              checkPapersRoute,
              targetPapersData,
              setTargetPapersData,
              setCheckPapersRoute,
              setLoadingPapersTableData,
              authHeader,
              setPapersTotalCount,
            );
          } else if (activeTableButton === 2) {
            fetchTableData(
              financingRoute,
              checkFinancingRoute,
              targetFinancingData,
              setTargetFinancingData,
              setCheckFinancingRoute,
              setLoadingFinancingTableData,
              authHeader,
              setFinancingTotalCount,
            );
          }
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        }
      }
    };
    fetchData();
  }, [patentsRoute, papersRoute, financingRoute, activeTableButton]);

  //Fetches related diseases data
  useEffect(() => {
    setLoadingIndexChartData(true);
    setLoadingPublicationChartData(true);
    const fetchData = async () => {
      if (targetId) {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          //Fetches index and publication data using Promise.allSettled
          Promise.allSettled([
            axios.get(indexRoute, {
              headers: authHeader(),
            }),
            axios.get(publicationRoute, {
              headers: authHeader(),
            }),
          ])
            .then(([indexResponse, publicationResponse]) => {
              const successfulResponses = [];
              const failedResponses = [];

              //Separates successful and failed responses
              for (const response of [indexResponse, publicationResponse]) {
                if (response.status === 'fulfilled') {
                  successfulResponses.push(response.value.data);
                } else {
                  failedResponses.push(response.reason);
                }
              }

              //Sets state variables for index and publication data
              setTargetIndexData(successfulResponses[0]);
              setTargetPublicationData(successfulResponses[1]);

              // Logs errors for failed responses
              if (failedResponses.length > 0) {
                console.error(failedResponses);
              }
            })
            .finally(() => {
              setLoadingIndexChartData(false);
              setLoadingPublicationChartData(false);
            });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        }
      }
    };
    fetchData();
  }, [selectedDisease, selectedCategory, indexPeriodFrom, indexPeriodTo]);

  /*
  Filter the related diseases data based on selected disease and selected category.
  Create arrays of unique diseases and therapeutic categories based on the filtered data.
  @param relatedDiseasesData {Array} The related diseases data to filter
  @param selectedDisease {String} The selected disease ID and name string
  @param selectedCategory {String} The selected therapeutic category ID and name string
  @returns {Void}
  */
  let therapeuticCategories = [];
  let diseases = [];

  let targetName;

  if (relatedDiseasesData) {
    const filteredData = relatedDiseasesData.filter(
      (item) =>
        (selectedDisease ? item.Disease_ID + '|' + item.Disease_name === selectedDisease : true) &&
        (selectedCategory
          ? item.Therapeutic_categories.some(
              (category) =>
                category.Therapeutic_category_ID + '|' + category.Therapeutic_category_name ===
                selectedCategory,
            )
          : true),
    );
    diseases = [
      ...new Set(
        filteredData && filteredData.map((item) => item.Disease_ID + '|' + item.Disease_name),
      ),
    ];
    therapeuticCategories = [
      ...new Set(
        filteredData &&
          filteredData
            .flatMap((item) => item.Therapeutic_categories)
            .map((item) => {
              if (item.Therapeutic_category_ID && item.Therapeutic_category_name) {
                return {
                  id: item.Therapeutic_category_ID,
                  name: item.Therapeutic_category_name,
                };
              } else {
                return null;
              }
            })
            .filter((item) => item !== null)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => item.id + '|' + item.name),
      ),
    ];
  }

  /*
  Update the selected disease state based on user selection.
  @param disease {String} The selected disease ID and name string
  @returns {Void}
  */
  const handleDiseaseChange = (disease) => {
    setSelectedDisease(disease);
    // setSelectedCategory(null);
  };

  /*
  Update the selected category state based on user selection.
  @param category {String} The selected therapeutic category ID and name string
  @returns {Void}
  */
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  if (targetData) {
    try {
      const targetId = targetData.ID;
      const targetTitle = targetData.Protein_meta.Primary_accession
        ? `${targetData.Protein_meta.Primary_accession} ${
            targetData.Uniprot_id ? '- ' + targetData.Uniprot_id : ''
          }`
        : targetData.Protein_meta.Description.submission_names.length > 0 &&
          targetData.Protein_meta.Description.submission_names[0]
        ? targetData.Protein_meta.Description.submission_names[0]
        : '';
      targetName = targetId + '|' + targetTitle;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className='page'>
      {targetErr ? (
        <div className='results_container'>
          <span className='results_search_container'>
            <span className='empty_container'>
              <EmptyBoxIcon />
              <span className='text_container'>
                <span className='text_header'>No results were found for</span>
                <span className='text'>{targetUniprotId}</span>
              </span>
            </span>
          </span>
        </div>
      ) : !targetData ? (
        <LoadingSpinner page />
      ) : (
        <span className='page_container'>
          <PageTitle title={targetName && targetName.split('|')[1]} />
          {(targetData.Protein_meta || targetData.Genes) && (
            <TransitionExpandComp
              header='Description'
              description={targetData.Protein_meta && targetData.Protein_meta}
              gene={targetData.Genes && targetData.Genes}
            />
          )}
          <div className='target_container padding'>
            <FilteringComp
              loadingData={loadingData}
              catValue={selectedCategory}
              onCatValue={handleCategoryChange}
              diseaseValue={selectedDisease}
              onDiseaseValue={handleDiseaseChange}
              therapyCat={therapeuticCategories}
              disease={diseases}
              targetName={targetName}
            />
            <IndexChart
              indexData={targetIndexData && targetIndexData.Relevance_index}
              actualIndexData={targetIndexData && targetIndexData.Actual_index}
              onRangeChange={onRangeChange}
              loadingData={loadingIndexChartData}
            />
            <PublicationChart
              publicationData={targetPublicationData && targetPublicationData}
              loadingData={loadingPublicationChartData}
            />
            <OtherDataTable
              patentsData={
                targetPatentsData && targetPatentsData.Patents && targetPatentsData.Patents
              }
              papersData={targetPapersData && targetPapersData.Papers && targetPapersData.Papers}
              financingData={
                targetFinancingData && targetFinancingData.News && targetFinancingData.News
              }
              activeButton={activeTableButton}
              setActiveButton={setActiveTableButton}
              handleButtonClick={handleTableButtonClick}
              handleOnSearchChange={handleOnSearchChange}
              loadingData={
                activeTableButton === 0
                  ? loadingPatentsTableData
                  : activeTableButton === 1
                  ? loadingPapersTableData
                  : loadingFinancingTableData
              }
              currentPaginationPage={
                activeTableButton === 0
                  ? patentsPageCap
                  : activeTableButton === 1
                  ? papersPageCap
                  : financingPageCap
              }
              totalCount={
                activeTableButton === 0
                  ? patentsTotalCount
                  : activeTableButton === 1
                  ? papersTotalCount
                  : financingTotalCount
              }
              handlePaginationPageChange={handlePaginationPageChange}
            />
          </div>
        </span>
      )}
    </div>
  );
};

export default Target;
