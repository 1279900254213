// Importing the necessary modules
import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { Context } from './helpers/context';
import { LOGOUT_URL } from './helpers/config';
import secureLocalStorage from 'react-secure-storage';
import { Router } from './router/Router';
import './app.scss';

function App() {
  // Using the useState hook to manage user state
  const [user, setUser] = useState(JSON.parse(secureLocalStorage.getItem('user')));
  const [accessToken, setAccessToken] = useState(
    JSON.parse(secureLocalStorage.getItem('accessToken')),
  );
  const [refreshToken, setRefreshToken] = useState(
    JSON.parse(secureLocalStorage.getItem('refreshToken')),
  );
  // Using the useEffect hook to set user, access token, and refresh token on mount
  useEffect(() => {
    if (user) {
      setUser(user);
    }
    if (accessToken) {
      setAccessToken(accessToken);
    }
    if (refreshToken) {
      setRefreshToken(refreshToken);
    }
  }, []);
  // Defining a login function that sets user, access token, and refresh token in local storage and state
  const login = (user) => {
    const { access_token, refresh_token } = user;
    const userData = jwt_decode(access_token);
    secureLocalStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
    secureLocalStorage.setItem('accessToken', JSON.stringify(access_token));
    setAccessToken(access_token);
    secureLocalStorage.setItem('refreshToken', JSON.stringify(refresh_token));
    setRefreshToken(refresh_token);
  };
  // Defining a logout function that clears local storage and sets user state to null
  const logout = async () => {
    await fetch(LOGOUT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${refreshToken}`,
      },
    });
    secureLocalStorage.clear();
    localStorage.setItem('prevPath', window.location.pathname);
    setUser(null);
  };
  // Defining a refresh function that updates the user, access token, and refresh token in local storage and state
  const refresh = (tokensData) => {
    const { access_token, refresh_token } = tokensData;
    const userData = jwt_decode(access_token);
    secureLocalStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
    secureLocalStorage.setItem('accessToken', JSON.stringify(access_token));
    setAccessToken(access_token);
    secureLocalStorage.setItem('refreshToken', JSON.stringify(refresh_token));
    setRefreshToken(refresh_token);
  };
  // Return the Context provider with all the state and functions
  return (
    <div className='main_container'>
      <Context.Provider value={{ user, accessToken, refreshToken, login, logout, refresh }}>
        <Router />
      </Context.Provider>
    </div>
  );
}

export default App;
