import { Link } from 'react-router-dom';
import { formatRevertDashDate } from '../../helpers/formatDates';

//Function of returning frequency
const getFrequencyText = (frequency) => {
  switch (frequency) {
    case 1:
      return 'Week';
    case 2:
      return '2 weeks';
    case 3:
      return 'Month';
    case 4:
      return '2 moths';
    case 5:
      return 'Quarter';
    default:
      return '';
  }
};

//Subscription table columns
export const columns = [
  {
    title: '№',
    dataIndex: 'key',
    key: 'key',
    width: 42,
    overflow: 'wrap',
  },
  {
    title: 'Target',
    dataIndex: 'uniprotID',
    key: 'uniprotID',
    render: (text, record) => (
      <Link className='table_header_link' to={`/trends/target/${record.uniprotID}`}>
        {text}
      </Link>
    ),
    width: 210,
    overflow: 'wrap',
  },
  {
    title: 'Therapeutic category',
    dataIndex: 'therapyCat',
    key: 'therapyCat',
    width: 310,
    overflow: 'wrap',
  },
  { title: 'Disease', dataIndex: 'disease', key: 'disease', width: 310, overflow: 'wrap' },
  {
    title: 'Frequency every',
    dataIndex: 'frequency',
    key: 'frequency',
    render: (text) => <span>{getFrequencyText(text)}</span>,
    width: 142,
    overflow: 'wrap',
  },
  {
    title: 'Issue date',
    dataIndex: 'issueDate',
    key: 'issueDate',
    render: (text) => <span>{text && formatRevertDashDate(text)}</span>,
    width: 132,
    overflow: 'wrap',
  },
];
