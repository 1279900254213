export const getTableSubscriptionsData = (subsData) => {
  let dataSubsTable = [];
  // add relevant data to subsData array

  if (subsData) {
    for (let i = 0; i < subsData.length; ++i) {
      dataSubsTable.push({
        key: i + 1,
        subId: subsData[i].ID,
        targetID: subsData[i].Protein_fk,
        uniprotID: subsData[i].Uniprot_id,
        primary: subsData[i].Primary_accession,
        categoryID: subsData[i].Therapics_cat_fk,
        therapyCat: subsData[i].Therapeitic_category_name,
        diseaseID: subsData[i].Disease_fk,
        disease: subsData[i].Disease_name,
        frequency: subsData[i].Period,
        issueDate: subsData[i].Start_date,
        sendTime: subsData[i].Send_time,
      });
    }
  }
  return dataSubsTable;
};
