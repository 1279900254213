import { Tooltip } from 'antd';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';

//get Patents table columns
export const patentColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 610,
    overflow: 'wrap',
  },
  {
    title: 'Patent date',
    dataIndex: 'dateIssued',
    key: 'dateIssued',
    width: 140,
    overflow: 'wrap',
    sorter: (a, b) => {
      const dateA = new Date(a.dateIssued);
      const dateB = new Date(b.dateIssued);
      return dateA - dateB;
    },
  },
  {
    title: 'Application date',
    dataIndex: 'dateField',
    key: 'dateField',
    width: 170,
    overflow: 'wrap',
    sorter: (a, b) => {
      const dateA = new Date(a.dateField);
      const dateB = new Date(b.dateField);
      return dateA - dateB;
    },
  },

  {
    title: 'Patent number',
    dataIndex: 'patentNumber',
    key: 'patentNumber',
    width: 160,
    overflow: 'wrap',
  },
  {
    title: 'Application number',
    dataIndex: 'applicationNumber',
    key: 'applicationNumber',
    render: (text) => {
      return (
        <>
          {text && text.split('|')[0] !== 'null' && (
            <a
              className='source_link_global'
              href={text && text.split('|')[1]}
              target='_blank'
              rel='noreferrer'
            >
              {text && text.split('|')[0]}
            </a>
          )}
        </>
      );
    },
    width: 180,
    overflow: 'wrap',
  },
];

//get Papers table columns
export const articleColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 450,
    overflow: 'wrap',
  },
  {
    title: 'Publication Date',
    dataIndex: 'publicationDate',
    key: 'publicationDate',
    width: 140,
    overflow: 'wrap',
    sorter: (a, b) => {
      const dateA = new Date(a.publicationDate);
      const dateB = new Date(b.publicationDate);
      return dateA - dateB;
    },
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    width: 200,
    overflow: 'wrap',
  },
  {
    title: 'DOI',
    dataIndex: 'doi',
    key: 'doi',
    render: (text) => {
      return (
        <>
          {text ? (
            <Tooltip
              placement='topLeft'
              title={
                <span className='tooltip_title_global'>
                  {text}{' '}
                  <CopyIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigator.clipboard.writeText(text)}
                  />
                </span>
              }
            >
              <a
                className='source_link_global'
                href={`https://dx.doi.org/${text}`}
                target='_blank'
                rel='noreferrer'
              >
                {text && text.toString().substring(0, 6)}...
              </a>
            </Tooltip>
          ) : (
            <span>-</span>
          )}
        </>
      );
    },
    width: 120,
    overflow: 'wrap',
  },
];

//get Financing table columns
export const financingColumns = [
  {
    title: 'Title',
    dataIndex: 'name',
    key: 'name',
    // width: 602,
    overflow: 'wrap',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 120,
    overflow: 'wrap',
  },
  {
    title: 'Resource',
    dataIndex: 'resource',
    render: (text) => {
      return (
        <>
          {text && (
            <a className='source_link_global' href={text} target='_blank' rel='noreferrer'>
              {text && text.replace(/^(https?:\/\/)?(www\.)?/, '').split('/')[0]}
            </a>
          )}
        </>
      );
    },
    key: 'resource',
    width: 200,
    overflow: 'wrap',
  },
];
