import React from 'react';
import './tooltip.scss';

/*
Create a custom tooltip component for displaying data with labels
@param data {Array} An array of objects containing label and value
@param isIndexChart {Boolean} Whether the tooltip is for an index chart
@param isPublicationChart {Boolean} Whether the tooltip is for a publication chart
@returns {JSX.Element}
*/
export const CustomTooltip = ({ data, isIndexChart, isPublicationChart }) => {
  // Filter out objects with no value and create an element for each field with its label and value
  const fieldElements = data
    .filter((field) => field.value)
    .map((field, index) => (
      <p key={index}>
        {isIndexChart ? (
          <span
            style={{
              marginRight: 8,
            }}
          >
            {field.label}
          </span>
        ) : isPublicationChart ? (
          <span
            style={{
              color:
                field.label === 'N/A'
                  ? '#BAB3BC'
                  : field.label === 'Q1'
                  ? '#605DEC'
                  : field.label === 'Q2'
                  ? '#44BCFF'
                  : field.label === 'Q3'
                  ? '#CC33FF'
                  : field.label === 'Q4'
                  ? '#FFC420'
                  : 'black',
              marginRight: 8,
            }}
          >
            {field.label}:
          </span>
        ) : null}
        <span className='tooltip_value_text'>{field.value}</span>
      </p>
    ));
  // Return the custom tooltip component with the created field elements
  return (
    <div className='custom-tooltip'>
      <div>{fieldElements}</div>
    </div>
  );
};
