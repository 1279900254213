import React from 'react';
import { Select, Spin } from 'antd';
import { Spinner } from '../LoadingComponent/Spinner';

import './select.scss';

/*
Render a Select component with options fetched from the server or given as props
@param onFilterValue {Function} Function to be called when a new value is selected
@param selectOptions {Array} An array of options to be displayed in the select. Can be null if isServerSideFunc is true.
@param selectDefaultValue {String} The default value to be selected
@param selectValue {String} The currently selected value
@param selectStyles {Object} An object containing styles for the Select component
@param selectSize {String} The size of the select. Defaults to 'large'
@param isShowSearch {Boolean} Whether to show the search input in the select. Defaults to true
@param isAllowClear {Boolean} Whether the select can be cleared. Defaults to true
@param selectClassName {String} The className for the Select component
@param placeholder {String} The placeholder text to be displayed when no value is selected
@param isServerSideFunc {Boolean} Whether to use server-side rendering for the options. Defaults to false
@param handleSearch {Function} Function to be called when the search input is changed
@param handleLoadMore {Function} Function to be called when the user scrolls to the bottom of the options menu
@param searchValue {String} The current value of the search input
@returns {JSX.Element}
*/
export const SelectingComp = ({
  onFilterValue,
  selectOptions,
  selectDefaultValue,
  selectValue,
  selectStyles,
  selectSize,
  isShowSearch,
  isAllowClear,
  selectClassName,
  placeholder,
  // optionFilterProp,
  // filterOption,
  // filterSort,
  loadingData,
  isServerSideFunc,
  handleSearch,
  handleLoadMore,
  searchValue,
}) => {
  return (
    <>
      {isServerSideFunc ? (
        <Select
          showSearch
          allowClear
          style={selectStyles}
          size={selectSize ? selectSize : 'large'}
          className={selectClassName}
          placeholder={placeholder}
          optionFilterProp='children'
          defaultValue={selectDefaultValue}
          searchValue={searchValue}
          onChange={onFilterValue}
          onSearch={handleSearch}
          loading={loadingData}
          notFoundContent={loadingData ? <Spin indicator={<Spinner />} /> : 'No data found'}
          onPopupScroll={(e) => {
            const target = e.target;
            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
              handleLoadMore();
            }
          }}
          dropdownRender={(menu) => {
            return (
              <div>
                {menu}
                {loadingData && (
                  <span className='select_loading_container'>
                    <Spin indicator={<Spinner />} />
                  </span>
                )}
              </div>
            );
          }}
        >
          {selectOptions &&
            selectOptions.map((option, index) => (
              <Select.Option key={index} value={option} disabled={loadingData}>
                {option.toString().split('|')[1]}
              </Select.Option>
            ))}
        </Select>
      ) : (
        <Select
          onChange={onFilterValue}
          showSearch={isShowSearch}
          allowClear={isAllowClear}
          defaultValue={selectDefaultValue}
          value={selectValue}
          style={selectStyles}
          size={selectSize ? selectSize : 'large'}
          className={selectClassName}
          placeholder={placeholder}
          // optionFilterProp={optionFilterProp}
          // filterOption={filterOption}
          // filterSort={filterSort}
          loading={loadingData}
        >
          {selectOptions &&
            selectOptions.map((option) => (
              <Select.Option key={option} value={option} disabled={loadingData}>
                {option.toString().split('|')[1]}
              </Select.Option>
            ))}
        </Select>
      )}
    </>
  );
};
