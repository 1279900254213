//config file with routes to access server responses
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_DEVELOPMENT;

export const TARGET_URL = `${API_URL}/target`;
export const TARGET_PRIMARY_URL = `${API_URL}/targets_ID`;

export const TRENDS_CATEGORIES_URL = `${API_URL}/therapeutic_category`;
export const TRENDS_DISEASES_URL = `${API_URL}/diseases_therapeutic_category`;
export const TRENDS_TARGETS_INDEX_URL = `${API_URL}/targets_index`;
export const TRENDS_BIG_CHANGE_URL = `${API_URL}/last_targets_index_big_change`;

export const SUBSCRIPTIONS_URL = `${API_URL}/subscriptions`;

export const LOGIN_URL = `${API_URL}/login`;
export const REFRESH_URL = `${API_URL}/refresh`;
export const VALID_TOKEN_URL = `${API_URL}/is_valid`;
export const LOGOUT_URL = `${API_URL}/logout`;
