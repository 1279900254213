/*
Creates a new array by filling missing periods with an object
having the year and null amount value.
@param minYear {Number} The minimum year to fill
@param maxYear {Number} The maximum year to fill
*/
export const fillingPeriodArray = (minYear, maxYear, array) => {
  const newData = [];
  for (let i = minYear; i <= maxYear; i++) {
    const objs = array.filter((o) => {
      const year = typeof o.Year === 'number' ? o.Year : new Date(o.Year).getFullYear();
      return year === i;
    });

    if (objs.length > 0) {
      newData.push(...objs);
    } else {
      newData.push({ Year: i, Amount: null });
    }
  }

  return newData;
};
