import React from 'react';
import { Input } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

import './search_input.scss';

const { Search } = Input;

/*
A reusable search input component with optional search system styling
@param isSearchSystem {Boolean} Whether or not to style the input like the search system
@param onSearch {Function} Function to be called when the user submits a search
@param enterButton {ReactNode} React element to be used as the enter button (optional)
@param onChange {Function} Function to be called when the input value changes
@param onPressEnter {Function} Function to be called when the user presses enter
@param value {String} Value of the input
@param placeholder {String} Placeholder text for the input
@param prefix {ReactNode} React element to be used as a prefix to the input (optional)
@param allowClear {Boolean} Whether or not to show a clear button (optional)
@param size {String} Size of the input (optional)
@param bordered {Boolean} Whether or not to show border (optional)
@param style {Object} Style object to be applied to the input (optional)
@param suffix {ReactNode} React element to be used as a suffix to the input (optional)
@param maxLength {Number} Maximum length of the input (optional)
@param minLength {Number} Minimum length of the input (optional)
@param loading {Boolean} Whether or not to show a loading icon in the input
*/
export const SearchInput = ({
  isSearchSystem,
  onSearch,
  enterButton,
  onChange,
  onPressEnter,
  value,
  placeholder,
  prefix,
  allowClear,
  size,
  bordered,
  style,
  suffix,
  maxLength,
  minLength,
  loading,
}) => {
  return (
    <>
      {isSearchSystem ? (
        <Search
          autoFocus
          placeholder={placeholder}
          prefix={value ? null : <SearchOutlined style={{ color: '#BAB3BC' }} />}
          allowClear={allowClear}
          onSearch={value.length >= minLength && onSearch}
          onChange={onChange}
          size={size ? size : 'large'}
          bordered={bordered}
          style={style}
          value={value && value}
          suffix={!loading && suffix && suffix}
          enterButton={
            loading ? (
              <LoadingOutlined style={{ fontSize: 18, color: '#BAB3BC' }} spin />
            ) : enterButton ? (
              enterButton
            ) : (
              <SearchIcon stroke={value.length >= minLength ? '#CC33FF' : '#BAB3BC'} />
            )
          }
          maxLength={maxLength ? maxLength : 256}
          minLength={minLength ? minLength : 1}
          disabled={loading}
        />
      ) : (
        <Input
          placeholder={placeholder}
          prefix={prefix ? prefix : <SearchOutlined style={{ color: '#BAB3BC' }} />}
          allowClear={allowClear}
          onChange={onChange}
          onPressEnter={onPressEnter && onPressEnter}
          size={size ? size : 'large'}
          bordered={bordered}
          style={style}
          value={value && value}
          suffix={suffix && suffix}
          maxLength={maxLength ? maxLength : 256}
          loading={loading}
        />
      )}
    </>
  );
};
