import React from 'react';
import './dynamic_trend.scss';
import { ReactComponent as UpIcon } from '../../../assets/icons/trendIcons/up.svg';
import { ReactComponent as DownIcon } from '../../../assets/icons/trendIcons/down.svg';
import { ReactComponent as StagnationIcon } from '../../../assets/icons/trendIcons/stagnation.svg';

/*
 Displays dynamic trend icon and percentage change.
 @param {string} dynamicTrend - The type of trend ('up', 'down', 'stagnation').
 @param {number} dynamicTrendData - The percentage change to display.
 */
export const DynamicTrendComp = ({ dynamicTrend, dynamicTrendData }) => {
  return (
    <span className='dynamic_trend_container'>
      {dynamicTrend === 'up' ? (
        <>
          <UpIcon />
          {dynamicTrendData && <p className='dynamic_up_text'>+{dynamicTrendData}</p>}
        </>
      ) : dynamicTrend === 'down' ? (
        <>
          <DownIcon />
          {dynamicTrendData && <p className='dynamic_down_text'>{dynamicTrendData}</p>}
        </>
      ) : (
        <>
          <StagnationIcon />
          {dynamicTrendData && <p className='dynamic_stagnation_text'>{dynamicTrendData}</p>}
        </>
      )}
    </span>
  );
};
