import { SUBSCRIPTIONS_URL } from '../../../helpers/config';
import { getQueryParam } from '../../../helpers/queryFunctions';

//routes for subs
const diseasesRoute = `${SUBSCRIPTIONS_URL}/subscription_diseases`;
const categoriesRoute = `${SUBSCRIPTIONS_URL}/subscription_therapeutic_cats`;
const targetsRoute = `${SUBSCRIPTIONS_URL}/subscription_targets`;
//subs diseases routes

export const getSubDiseasesRoute = (pageCap, name) =>
  `${diseasesRoute}?page=${pageCap}&name=${name}`;

export const getSubDiseasesWithCategoryRoute = (pageCap, name, selectedCategory) =>
  `${diseasesRoute}?page=${pageCap}&${getQueryParam(
    selectedCategory,
    'therapeutic_cat_id',
  )}&name=${name}`;

export const getSubDiseasesWithTargetRoute = (pageCap, name, selectedTarget) =>
  `${diseasesRoute}?page=${pageCap}&${getQueryParam(selectedTarget, 'protein_id')}&name=${name}`;

export const getSubDiseasesWithCategoryAndTargetRoute = (
  pageCap,
  name,
  selectedCategory,
  selectedTarget,
) =>
  `${diseasesRoute}?page=${pageCap}&${getQueryParam(
    selectedCategory,
    'therapeutic_cat_id',
  )}&${getQueryParam(selectedTarget, 'protein_id')}&name=${name}`;

//subs categories routes

export const getSubCategoriesRoute = (name) => `${categoriesRoute}?name=${name}`;

export const getSubCategoriesWithDiseaseRoute = (name, selectedDisease) =>
  `${categoriesRoute}?${getQueryParam(selectedDisease, 'disease_id')}&name=${name}`;

export const getSubCategoriesWithTargetRoute = (name, selectedTarget) =>
  `${categoriesRoute}?${getQueryParam(selectedTarget, 'protein_id')}&name=${name}`;

export const getSubCategoriesWithDiseaseAndTargetRoute = (name, selectedDisease, selectedTarget) =>
  `${categoriesRoute}?${getQueryParam(selectedDisease, 'disease_id')}&${getQueryParam(
    selectedTarget,
    'protein_id',
  )}&name=${name}`;

//subs targets routes

export const getSubTargetsRoute = (pageCap, name) => `${targetsRoute}?page=${pageCap}&name=${name}`;

export const getSubTargetsWithDiseaseRoute = (pageCap, name, selectedDisease) =>
  `${targetsRoute}?page=${pageCap}&${getQueryParam(selectedDisease, 'disease_id')}&name=${name}`;

export const getSubTargetsWithCategoryRoute = (pageCap, name, selectedCategory) =>
  `${targetsRoute}?page=${pageCap}&${getQueryParam(
    selectedCategory,
    'therapeutic_cat_id',
  )}&name=${name}`;

export const getSubTargetsWithDiseaseAndCategoryRoute = (
  pageCap,
  name,
  selectedDisease,
  selectedCategory,
) =>
  `${targetsRoute}?page=${pageCap}&${getQueryParam(selectedDisease, 'disease_id')}&${getQueryParam(
    selectedCategory,
    'therapeutic_cat_id',
  )}&name=${name}`;
