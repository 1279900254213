import React, { useState, useEffect, useContext } from 'react';
import { EditOutlined, DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

import { getTableSubscriptionsData } from './getTableData';
import { columns } from './tableColumns';

import { successMessage } from '../../helpers/messageUtils';
import { authHeader, refreshFunction } from '../../helpers/authFunctions';
import { VALID_TOKEN_URL, SUBSCRIPTIONS_URL } from '../../helpers/config';
import { Context } from '../../helpers/context';

import { DropdownComp } from '../../components/UI/DropdownComp/DropdownComp';
import { LoadingSpinner } from '../../components/UI/LoadingComponent/LoadingComponent';
import { PageTitle } from '../../components/UI/PageTitle/PageTitle';
import { ButtonComp } from '../../components/UI/ButtonComp/ButtonComp';
import { SubscriptionModal } from '../../components/UI/AddSubsModal/SubscriptionModal';

import TableComp from '../../components/Table/Table';

import './my_subs.scss';

const MySubscriptions = () => {
  const { refresh, refreshToken, logout } = useContext(Context);

  // State for the currently selected subscription data and whether a modal for creating or updating a subscription is open
  const [selectedData, setSelectedData] = useState();
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  // State for the user's subscription data and whether it's currently being loaded
  const [subsData, setSubsData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  // State to trigger a refresh of the subscription data
  const [isSmthNew, setIsSmthNew] = useState(0);

  // Show the modal for creating a new subscription
  const showCreateModal = () => {
    setOpenCreate(true);
  };

  // Show the modal for updating an existing subscription and set the selectedData state to the current subscription data
  const showUpdateModal = (data) => {
    setSelectedData(data);
    setOpenUpdate(true);
    setIsUpdateModal(true);
  };

  // Load the user's subscription data when the component mounts or when isSmthNew changes
  useEffect(() => {
    setLoadingData(true);
    const fetchData = async () => {
      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        const response = await axios.get(SUBSCRIPTIONS_URL, { headers: authHeader() });
        setSubsData(response.data);
      } catch (error) {
        // If the user's token is invalid, refresh it and try again
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, [isSmthNew]);

  // Delete a subscription
  const deleteSubscription = async (subId) => {
    const data = {
      subscription_id: subId,
    };
    try {
      setLoadingData(true);
      await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
      await axios.delete(SUBSCRIPTIONS_URL, { headers: authHeader(), data });
      setIsSmthNew(isSmthNew + 1);
      successMessage('Subscription deleted');
    } catch (error) {
      // If the user's token is invalid, refresh it and try again
      refreshFunction(error, deleteSubscription, refreshToken, refresh, logout);
    } finally {
      setLoadingData(false);
    }
  };

  // Format the subscription data into a format suitable for displaying in a table
  const dataSubsTable = getTableSubscriptionsData(subsData && subsData.Subscriptions);

  // Handle a click on a menu item in the subscription table
  const handleMenuClick = (e, data) => {
    if (e.key === '1') {
      // Edit the subscription
      showUpdateModal(data);
    } else if (e.key === '2') {
      // Delete the subscription
      deleteSubscription(data.subId, data.key);
    }
  };

  // Configure the menu for the subscription table
  const items = [
    { key: '1', label: 'Edit', icon: <EditOutlined style={{ color: '#BAB3BC', fontSize: 16 }} /> },
    {
      key: '2',
      label: 'Delete',
      icon: <DeleteOutlined style={{ color: '#BAB3BC', fontSize: 16 }} />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const columnsWithOperations = [
    ...columns,
    {
      dataIndex: 'operation',
      key: 'operation',
      render: (text, data) => (
        <DropdownComp menuProps={{ ...menuProps, onClick: (e) => handleMenuClick(e, data) }}>
          <a>
            <MoreOutlined style={{ color: '#BAB3BC', fontSize: 24, marginTop: 2 }} />
          </a>
        </DropdownComp>
      ),
      width: 56,
    },
  ];

  return (
    <div className='page'>
      {loadingData && !subsData ? (
        <LoadingSpinner page />
      ) : (
        <span className='page_container'>
          <PageTitle title='My subscriptions' />
          {dataSubsTable.length > 0 ? (
            <div>
              <TableComp
                dataParentTable={dataSubsTable}
                loadingData={loadingData}
                tableColumns={columnsWithOperations}
                showModal={showUpdateModal}
                deleteSubscription={deleteSubscription}
                isMySubs
              />
              <ButtonComp
                buttonClassName='btn_antd'
                type='text'
                buttonIcon={<PlusOutlined />}
                buttonSize='large'
                onButtonClick={showCreateModal}
              >
                Add Subscription
              </ButtonComp>
              <SubscriptionModal
                open={openCreate ? openCreate : openUpdate}
                setOpen={openCreate ? setOpenCreate : setOpenUpdate}
                isMySubs
                isUpdateModal={isUpdateModal}
                setIsUpdateModal={setIsUpdateModal}
                data={selectedData && selectedData}
                successMessage={successMessage}
                isSmthNew={isSmthNew}
                setIsSmthNew={setIsSmthNew}
              />
            </div>
          ) : (
            <div className='no_subs_container'>
              <p className='no_subs_text'>
                You don&apos;t have any subscriptions.
                <br /> Want to add?
              </p>
              <ButtonComp
                buttonClassName={['btn_antd', 'no_subs_btn_container']}
                type='text'
                buttonIcon={<PlusOutlined />}
                buttonSize='large'
                onButtonClick={showCreateModal}
              >
                Add Subscription
              </ButtonComp>
              <SubscriptionModal
                open={openCreate}
                setOpen={setOpenCreate}
                isMySubs
                successMessage={successMessage}
                isSmthNew={isSmthNew}
                setIsSmthNew={setIsSmthNew}
              />
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default MySubscriptions;
