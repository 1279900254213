import { DatePicker } from 'antd';
import './date_picker.scss';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
// Date format for the date picker component
const dateFormat = 'DD.MM.YYYY hh a';

// Date format for the range picker component
const rangeFormat = 'YYYY-MM-DD';

// Preset options for the range picker
const rangePresets = [
  { label: 'Month', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: 'Quarter', value: [dayjs().add(-3, 'months'), dayjs()] },
  { label: '6 M', value: [dayjs().add(-6, 'months'), dayjs()] },
  { label: 'Year', value: [dayjs().add(-1, 'year'), dayjs()] },
  // { label: 'All Time', value: [dayjs('1970-01-01'), dayjs()] },
];

// Function to disable dates that are before the current date or are today's date
const disabledDate = (current) => {
  return current && (current <= dayjs().startOf('day') || current.isBefore(dayjs()));
};

// Date picker component
export const DatePickerComp = ({
  presets,
  onChange,
  onOk,
  placement,
  size,
  isRange,
  showTime,
  disabledDates,
  disabled,
  defaultDateValue,
}) => {
  return (
    <>
      {isRange ? (
        // Range picker component with presets options and specified format
        <RangePicker
          allowEmpty={[true, true]}
          presets={presets && rangePresets}
          onChange={onChange}
          placement={placement && placement}
          size={size ? size : 'large'}
          popupClassName='createDateRangePicker'
          format={rangeFormat}
        />
      ) : (
        // Date picker component with specified format and default value
        <DatePicker
          disabledDate={disabledDates && disabledDate}
          disabled={disabled && disabled}
          popupClassName='datePicker'
          showTime={showTime}
          onChange={onChange && onChange}
          onOk={onOk && onOk}
          format={dateFormat}
          defaultValue={defaultDateValue && dayjs(defaultDateValue)}
        />
      )}
    </>
  );
};
