import {
  TRENDS_CATEGORIES_URL,
  TRENDS_DISEASES_URL,
  TRENDS_TARGETS_INDEX_URL,
  TRENDS_BIG_CHANGE_URL,
} from '../../helpers/config';
import { getQueryParam } from '../../helpers/queryFunctions';

//routes with params for data from server in Trends page
export const getTrendsCategoriesDiseasesRelatedRoute = (selectedDisease) =>
  `${TRENDS_CATEGORIES_URL}?${getQueryParam(selectedDisease, 'disease')}`;

export const getTrendsDiseasesRoute = (pageCap, selectedCategory, searchValue) =>
  `${TRENDS_DISEASES_URL}?page=${pageCap}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}&name=${searchValue}`;

//targets index
export const getTrendsTargetsIndexRoute = (pageCap, perPage) =>
  `${TRENDS_TARGETS_INDEX_URL}?page=${pageCap}&per_page=${perPage}`;

export const getTrendsTargetsIndexWithCategoryRoute = (pageCap, perPage, selectedCategory) =>
  `${TRENDS_TARGETS_INDEX_URL}?page=${pageCap}&per_page=${perPage}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}`;

export const getTrendsTargetsIndexWithDiseaseRoute = (pageCap, perPage, selectedDisease) =>
  `${TRENDS_TARGETS_INDEX_URL}?page=${pageCap}&per_page=${perPage}&${getQueryParam(
    selectedDisease,
    'disease',
  )}`;
//new
export const getTrendsNewRoute = (pageCap, perPage) =>
  `${TRENDS_BIG_CHANGE_URL}?page=${pageCap}&per_page=${perPage}`;

export const getTrendsNewWithCategoryRoute = (pageCap, perPage, selectedCategory) =>
  `${TRENDS_BIG_CHANGE_URL}?page=${pageCap}&per_page=${perPage}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}`;

export const getTrendsNewWithDiseaseRoute = (pageCap, perPage, selectedDisease) =>
  `${TRENDS_BIG_CHANGE_URL}?page=${pageCap}&per_page=${perPage}&${getQueryParam(
    selectedDisease,
    'disease',
  )}`;
