import axios from 'axios';
/*
Function to extract query parameter from a value
@param value {string} Value to extract the query parameter from
@param param {string} The name of the query parameter to extract
@return {string} The query parameter extracted from the value or an empty string
*/
export const getQueryParam = (value, param) =>
  value && typeof value === 'string' ? `${param}=${value.split('|')[0]}` : '';

/*
Function to fetch table data
@param route {string} The URL endpoint for fetching the data
@param checkRoute {string} The current route being checked for fetching data
@param data {object} Data object to be set as the response from the fetch request
@param setData {function} Function to set the data state
@param setCheckRoute {function} Function to set the checkRoute state
@param setLoadingData {function} Function to set the loadingData state
@param authHeader {function} Function to retrieve authentication headers
@param setTableTotalCount {function} Function to set the tableTotalCount state
*/

export const fetchTableData = (
  route,
  checkRoute,
  data,
  setData,
  setCheckRoute,
  setLoadingData,
  authHeader,
  setTableTotalCount,
) => {
  if (route !== checkRoute || !data) {
    setLoadingData(true);
    setCheckRoute(route);
    axios
      .get(route, {
        headers: authHeader(),
      })
      .then((response) => {
        setData(response.data);
        setTableTotalCount(
          typeof response.data.Total_count === 'object'
            ? response.data.Total_count.count
            : response.data.Total_count,
        );
      })
      .finally(() => {
        setLoadingData(false);
      });
  }
};

/*
Function to check if data is found
@param data {object} The data object to check
@return {object|null} The data object if detail property is not "No object found" or null
*/
export const checkFoundData = (data) => {
  return data && data.detail ? null : data;
};

/*
Function to get the protein name from a data object
@param data {object} The data object to extract protein name from
@return {string} The protein name
*/
export const getProteinName = (data) => {
  let Result = '-';
  if (data) {
    const { recommended_name, alternative_names, submission_names } = data;
    Result =
      recommended_name?.full_name ??
      alternative_names?.find((n) => n.full_name)?.full_name ??
      submission_names?.[0] ??
      '-';
  }
  return Result;
};
