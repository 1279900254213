import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import './table.scss';
import { HorizontalList } from '../UI/HorizontalList/HorizontalList';
import { SearchInput } from '../UI/SearchInput/SearchInput';
import { Spinner } from '../UI/LoadingComponent/Spinner';

import { DescriptionTableComponent } from './DescriptionTable/DescriptionTableComp';

/*
Renders a table component with pagination and expandable rows.
@param {Array} buttonNames - An array of button names to display in the table navigation.
@param {Array} dataParentTable - An array of data objects for the table.
@param {Boolean} loadingData - A flag to indicate if the table is currently loading data.
@param {Number} activeButton - The index of the currently active button.
@param {Function} setActiveButton - A function to set the active button.
@param {Function} handleButtonClick - A function to handle button click events.
@param {Array} tableColumns - An array of table column objects.
@param {Boolean} isSearchInTable - A flag to indicate if a search input should be displayed in the table navigation.
@param {Function} handleOnSearchChange - A function to handle search input changes.
@param {Boolean} isMySubs - A flag to indicate if the table is for the "My Subscriptions" page.
@param {Boolean} isTrends - A flag to indicate if the table is for the "Trends" page.
@param {Boolean} isTarget - A flag to indicate if the table is for the "Target" page.
@param {Object} expanded - An object containing an array of expanded row keys.
@param {Function} setExpanded - A function to set the expanded row keys.
@param {Number} currentPaginationPage - The current pagination page number.
@param {Number} totalCount - The total number of table rows.
@param {Function} handlePaginationPageChange - A function to handle pagination page changes.
@returns {JSX.Element} - A table component with pagination and expandable rows.
*/
const TableComp = ({
  buttonNames,
  dataParentTable,
  loadingData,
  activeButton,
  setActiveButton,
  handleButtonClick,
  tableColumns,
  isSearchInTable,
  handleOnSearchChange,
  isMySubs,
  isTrends,
  isTarget,
  expanded,
  setExpanded,
  currentPaginationPage,
  totalCount,
  handlePaginationPageChange,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [dataSource, setDataSource] = useState();

  const buttons =
    buttonNames && buttonNames.map((button, i) => ({ key: i + 1, name: button, active: isActive }));

  const handleOnClick = (i) => {
    setIsActive(i === activeButton);
    setActiveButton(i);
    handleButtonClick(i);
  };

  useEffect(() => {
    setDataSource(dataParentTable);
  }, [dataParentTable]);

  const handleExpandRow = (key) => {
    const index = expanded.expandedRowKeys.indexOf(key);
    if (index >= 0) {
      setExpanded({ expandedRowKeys: expanded.expandedRowKeys.filter((k) => k !== key) });
    } else {
      setExpanded({ expandedRowKeys: [...expanded.expandedRowKeys, key] });
    }
  };

  return (
    <span className='table_container'>
      {(buttonNames || isSearchInTable) && (
        <div className='table_navigation_container'>
          {buttonNames && (
            <HorizontalList buttons={buttons} activeButton={activeButton} onClick={handleOnClick} />
          )}
          {isSearchInTable && (
            <SearchInput
              placeholder='Table search'
              allowClear
              onChange={handleOnSearchChange}
              style={{ maxWidth: 360 }}
            />
          )}
        </div>
      )}
      <span className='table'>
        <Table
          columns={tableColumns}
          loading={loadingData && { indicator: <Spinner size={40} position /> }}
          expandedRowKeys={isMySubs ? null : expanded.expandedRowKeys}
          onExpand={isMySubs ? null : (expand, record) => handleExpandRow(record.key)}
          expandable={
            isMySubs
              ? null
              : {
                  expandedRowRender: (record) =>
                    DescriptionTableComponent(
                      record.childData,
                      isTrends && isTrends,
                      isTarget && isTarget,
                      activeButton,
                    ),
                  rowExpandable: (record) => record.name !== 'Not Expandable',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <DownOutlined
                        style={{ color: '#BAB3BC' }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <RightOutlined
                        style={{ color: '#BAB3BC' }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                }
          }
          dataSource={dataSource}
          pagination={{
            current: currentPaginationPage,
            total: totalCount,
            onChange: handlePaginationPageChange,
            showSizeChanger: false,
          }}
        />
      </span>
    </span>
  );
};

export default TableComp;
