import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { successMessage } from '../../helpers/messageUtils';

import { SubscriptionModal } from '../UI/AddSubsModal/SubscriptionModal';
import { ButtonComp } from '../UI/ButtonComp/ButtonComp';
import { SelectingComp } from '../UI/SelectingComp/SelectingComp';

import './filtering_comp.scss';
import '../../assets/scss/global_styles.scss';

// Component for filtering data with select options and search box
// It also includes a subscription modal for subscribing to selected options
const FilteringComp = ({
  loadingData,
  therapyCat,
  disease,
  targetName,
  catValue,
  onCatValue,
  diseaseValue,
  onDiseaseValue,
  isServerSideFunc,
  handleSearch,
  handleLoadMore,
  searchValue,
}) => {
  // State for showing subscription modal
  const [open, setOpen] = useState(false);

  // Function for opening subscription modal
  const showModal = () => {
    setOpen(true);
  };

  // Function for closing subscription modal
  const handleCancel = () => {
    setOpen(false);
  };

  // Render filtering options and subscription modal
  return (
    <span className='filtering_box'>
      <span className='filter_container'>
        <p className='filter_name'>Therapeutic category</p>
        <SelectingComp
          selectOptions={therapyCat}
          onFilterValue={onCatValue}
          selectValue={catValue}
          isShowSearch
          isAllowClear
          selectClassName='filter'
          placeholder='Search list'
          loadingData={loadingData}
        />
      </span>
      <span className='filter_container'>
        <p className='filter_name'>Disease</p>
        <SelectingComp
          selectOptions={disease}
          onFilterValue={onDiseaseValue}
          selectValue={diseaseValue}
          isShowSearch
          isAllowClear
          selectClassName='filter'
          placeholder='Search list'
          loadingData={loadingData}
          isServerSideFunc={isServerSideFunc && isServerSideFunc}
          handleSearch={handleSearch}
          handleLoadMore={handleLoadMore}
          searchValue={searchValue}
        />
      </span>
      <span className='btn_container'>
        <ButtonComp
          buttonClassName='text_btn_antd'
          type='text'
          buttonIcon={<PlusOutlined />}
          buttonSize='large'
          onButtonClick={showModal}
          buttonDisabled={catValue || diseaseValue || targetName ? false : true}
        >
          Subscribe
        </ButtonComp>
        <SubscriptionModal
          open={open}
          setOpen={setOpen}
          handleCancel={handleCancel}
          catValue={catValue}
          diseaseValue={diseaseValue}
          nameValue={targetName}
          successMessage={successMessage}
        />
      </span>
    </span>
  );
};

// Export FilteringComp component as default
export default FilteringComp;
