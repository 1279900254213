import React from 'react';
import './filtering_search_results.scss';
import '../../assets/scss/global_styles.scss';
import { SelectingComp } from '../UI/SelectingComp/SelectingComp';
import { DatePickerComp } from '../UI/DatePickerComp/DatePickerComp';
import { Radio } from 'antd';

/*
FilteringSearchResultsComp component
This component renders the search filtering UI for the search results page.

@props:
loadingData {boolean} - Indicates if data is being loaded
therapyCat {array} - List of therapy categories options for selection
disease {array} - List of disease options for selection
catValue {string} - Selected therapy category value
onCatValue {function} - Function called on therapy category value change
diseaseValue {string} - Selected disease value
onDiseaseValue {function} - Function called on disease value change
onDateChange {function} - Function called on date range change
searchVar {string} - Selected text search value ('or' or 'and')
onTextSearchVarChange {function} - Function called on text search value change
isServerSideFunc {boolean} - Indicates if server-side filtering is enabled
handleSearch {function} - Function called on search query submit
handleLoadMore {function} - Function called on load more search results
searchValue {string} - Search value

@returns:
JSX element of the FilteringSearchResultsComp component
*/
export const FilteringSearchResultsComp = ({
  loadingData,
  therapyCat,
  disease,
  catValue,
  onCatValue,
  diseaseValue,
  onDiseaseValue,
  onDateChange,
  searchVar,
  onTextSearchVarChange,
  isServerSideFunc,
  handleSearch,
  handleLoadMore,
  searchValue,
}) => {
  return (
    <span className='filtering_search_box'>
      <div className='filtering_search_container'>
        <span className='filtering_search_container_left'>
          <SelectingComp
            selectOptions={therapyCat}
            onFilterValue={onCatValue}
            selectValue={catValue}
            loadingData={loadingData}
            isShowSearch
            isAllowClear
            selectClassName='filter'
            placeholder='Therapeutic category'
          />
          <DatePickerComp isRange presets onChange={onDateChange} placement='bottomLeft' />
        </span>
        <span className='filtering_search_container_right'>
          <SelectingComp
            selectOptions={disease}
            onFilterValue={onDiseaseValue}
            selectValue={diseaseValue}
            isShowSearch
            isAllowClear
            selectClassName='filter'
            placeholder='Disease'
            isServerSideFunc={isServerSideFunc && isServerSideFunc}
            handleSearch={handleSearch}
            handleLoadMore={handleLoadMore}
            searchValue={searchValue}
            loadingData={loadingData}
          />
          <span className='text_search_container'>
            Text search
            <Radio.Group onChange={onTextSearchVarChange} value={searchVar} buttonStyle='outline'>
              <Radio value={'or'}>Or</Radio>
              <Radio value={'and'}>And</Radio>
            </Radio.Group>
          </span>
        </span>
      </div>
    </span>
  );
};
