import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../assets/icons/indexInfo.svg';

// Index tooltip
export const IndexTooltip = ({ indexTooltipData }) => {
  return (
    <Tooltip
      title={
        <div>
          <p>Index formula</p>
          <hr style={{ height: 10, visibility: 'hidden' }} />
          <div
            style={{
              display: 'flex',
              gap: 5,
            }}
          >
            {indexTooltipData.map((data, index) => (
              <span
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    lineHeight: '140%',
                  }}
                >
                  {data.value}
                </span>
                <span
                  style={{
                    color: '#bab3bc',
                    fontSize: 12,
                    lineHeight: '140%',
                  }}
                >
                  {data.label}
                </span>
              </span>
            ))}
          </div>
        </div>
      }
      overlayStyle={{
        paddingBottom: 0,
        minWidth: 420,
        paddingLeft: 15,
      }}
      overlayInnerStyle={{
        color: 'black',
        background: '#fff',
        border: '1px solid #FAFAFA',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
        borderRadius: '8px 8px 8px 0px',
        padding: '10px',
      }}
      showArrow={false}
      placement='topRight'
    >
      <InfoIcon style={{ cursor: 'pointer' }} />
    </Tooltip>
  );
};
