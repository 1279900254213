import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spin } from 'antd';

import { VALID_TOKEN_URL } from '../../helpers/config';
import { Context } from '../../helpers/context';
import { authHeader, refreshFunction } from '../../helpers/authFunctions';
import { formatFullTextRevertDashDate } from '../../helpers/formatDates';

import { getDataBaseInfoRoute } from '../GlobalSearchResults/globalSearchRoutes';

import { SearchInputBox } from '../../components/GlobalSearchComponents/SearchInputBox';
import { SearchCountBlock } from '../../components/GlobalSearchComponents/SearchCountBlock';
import { PageTitle } from '../../components/UI/PageTitle/PageTitle';
import { Spinner } from '../../components/UI/LoadingComponent/Spinner';

import './global_search.scss';

const GlobalSearch = () => {
  const navigate = useNavigate();
  const { refresh, refreshToken, logout } = useContext(Context);

  const [dataSource, setDataSource] = useState();
  const [dataBaseInfo, setDataBaseInfo] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const routes = {
    dataBaseInfo: getDataBaseInfoRoute(),
  };

  // This function is called when the input value in the search bar changes
  const handleOnChange = async (e) => {
    setDataSource(e.target.value);
  };

  // This function is called when the search button is clicked
  const onSearchClick = () => {
    dataSource && dataSource.length > 2 && navigate(`${dataSource}`);
  };

  // This effect is called once when the component mounts
  useEffect(() => {
    setLoadingData(true);
    const fetchData = async () => {
      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        const response = await axios.get(routes.dataBaseInfo, {
          headers: authHeader(),
        });
        setDataBaseInfo(response.data);
      } catch (error) {
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, []);

  const search_examples = ['2002250183', '13900', 'Kinase'];

  let lastUpdate = '-';
  let papersCount = '-';
  let patentsCount = '-';
  let financingCount = '-';

  if (dataBaseInfo) {
    lastUpdate = dataBaseInfo.Updated;
    papersCount = dataBaseInfo.Papers_meta_number;
    patentsCount = dataBaseInfo.Patents_meta_number;
    financingCount = dataBaseInfo.News_meta_number;
  }

  return (
    <div className='page'>
      <span className='page_container global_search_box'>
        <div className='global_search_container'>
          <PageTitle title='Find what you want' />
          <SearchInputBox
            dataSource={dataSource}
            setDataSource={setDataSource}
            handleOnChange={handleOnChange}
            onSearchClick={onSearchClick}
            search_examples={search_examples}
          />
        </div>

        <span className='search_update'>
          <span className='search_count_container'>
            <span className='search_count_container'>
              <SearchCountBlock label='Patents' count={patentsCount} loading={loadingData} />
              <SearchCountBlock label='Articles' count={papersCount} loading={loadingData} />
              <SearchCountBlock label='Financing' count={financingCount} loading={loadingData} />
            </span>
          </span>
          <span>
            Last update: &nbsp;
            {loadingData ? (
              <Spin indicator={<Spinner />} />
            ) : (
              <span> {formatFullTextRevertDashDate(lastUpdate)}</span>
            )}
          </span>
        </span>
      </span>
    </div>
  );
};

export default GlobalSearch;
