/*
Format date from dd.mm.yyyy to Month d yyyy
@param dateString {String} The date string to format
@returns {String}
*/
export const formatDotDate = (dateString) => {
  const regex = /^\d{2}\.\d{2}\.\d{4}$/; // regex to match "dd.mm.yyyy" format
  if (!regex.test(dateString)) {
    return dateString;
  }
  let dateParts = dateString.split('.');
  let year = dateParts[2];
  let month = new Date(year, dateParts[1] - 1, 1).toLocaleString('en-us', { month: 'short' });
  let day = dateParts[0];
  let newDateString = `${month} ${day}, ${year}`;
  return newDateString;
};

/*
Format date from dd-mm-yyyy to Month d yyyy
@param dateString {String} The date string to format
@returns {String}
 */
export const formatDashDate = (dateString) => {
  const regex = /^\d{2}-\d{2}-\d{4}$/; // regex to match "dd-mm-yyyy" format
  if (!regex.test(dateString)) {
    return dateString;
  }
  let dateParts = dateString.split('-');
  let year = dateParts[2];
  let month = new Date(year, dateParts[1] - 1, 1).toLocaleString('en-us', { month: 'short' });
  let day = dateParts[0];
  let newDateString = `${month} ${day}, ${year}`;
  return newDateString;
};
/*
Format date from yyyy-mm-dd to Mth d yyyy
@param dateString {String} The date string to format
@returns {String} 
 */
export const formatRevertDashDate = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return dateString;
  }
  let dateParts = dateString.split('-');
  let year = dateParts[0];
  let month = new Date(year, dateParts[1] - 1, 1).toLocaleString('en-us', { month: 'short' });
  let day = dateParts[2];
  let newDateString = `${month} ${day}, ${year}`;
  return newDateString;
};

//Format date from yyyy-mm-dd to Month d yyyy
export const formatFullTextRevertDashDate = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return dateString;
  }

  let dateParts = dateString.split('-');
  let year = dateParts[0];
  let month = new Date(year, dateParts[1] - 1, 1).toLocaleString('en-us', { month: 'long' });
  let day = dateParts[2];
  let newDateString = `${month} ${day}, ${year}`;
  return newDateString;
};

//format to Date/Time
export const formatToDateString = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}|${hours}:00:00`;
  return formattedDate;
};
/*
Converts a date string in yyyy-mm-dd|hh:mm:ss format to an ISO 8601 string
@param dateStr {String} The date string to convert
@returns {String}
*/
export const formatDateStringToISO = (dateStr) => {
  const [date, time] = dateStr.split('|');
  const [year, month, day] = date.split('-');
  const [hours, minutes, seconds] = time.split(':');
  const dateObj = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  return dateObj.toISOString();
};
/*
Formats a date string to Mth d yyyy format
@param dateString {String} The date string to format
@returns {String}
*/
export const formatFromDateTimeToString = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
  } else {
    return '-';
  }
};
