import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import {
  getSubDiseasesRoute,
  getSubDiseasesWithCategoryRoute,
  getSubDiseasesWithTargetRoute,
  getSubDiseasesWithCategoryAndTargetRoute,
  getSubCategoriesRoute,
  getSubCategoriesWithDiseaseRoute,
  getSubCategoriesWithTargetRoute,
  getSubCategoriesWithDiseaseAndTargetRoute,
  getSubTargetsRoute,
  getSubTargetsWithDiseaseRoute,
  getSubTargetsWithCategoryRoute,
  getSubTargetsWithDiseaseAndCategoryRoute,
} from './subscriptionsRoutes';
import './modal.scss';

import { VALID_TOKEN_URL, SUBSCRIPTIONS_URL } from '../../../helpers/config';
import { authHeader, refreshFunction } from '../../../helpers/authFunctions';
import { Context } from '../../../helpers/context';
import { formatToDateString, formatDateStringToISO } from '../../../helpers/formatDates';

import { SelectingComp } from '../SelectingComp/SelectingComp';
import { SelectFrequencyComp } from '../SelectFrequencyComp/SelectFrequencyComp';
import { selectFrequencyOptions } from '../SelectFrequencyComp/selectFrequencyOptions';
import { LoadingSpinner } from '../LoadingComponent/LoadingComponent';

import { ReactComponent as ModalCloseIcon } from '../../../assets/icons/modalClose.svg';
import '../../../assets/scss/global_styles.scss';

/*
Component for rendering subscription modal
@param {Object} props - Props for SubscriptionModal
@param {Boolean} props.open - Whether the modal is open or not
@param {Function} props.setOpen - Function to set the open state of modal
@param {String} props.nameValue - Value of name field
@param {String} props.catValue - Value of category field
@param {String} props.diseaseValue - Value of disease field
@param {Boolean} props.isMySubs - Whether the subscription is from 'My Subscriptions' page or not
@param {Boolean} props.isUpdateModal - Whether the subscription is being updated or not
@param {Function} props.setIsUpdateModal - Function to set the state of isUpdateModal
@param {Object} props.data - Subscription data to be edited, null if it is new
@param {String} props.successMessage - Success message for subscription
@param {Boolean} props.isSmthNew - Whether the user entered something new or not
@param {Function} props.setIsSmthNew - Function to set the state of isSmthNew
@returns {JSX.Element}
*/
export const SubscriptionModal = ({
  open,
  setOpen,
  nameValue,
  catValue,
  diseaseValue,
  isMySubs,
  isUpdateModal,
  setIsUpdateModal,
  data,
  successMessage,
  isSmthNew,
  setIsSmthNew,
}) => {
  const { refresh, refreshToken, logout } = useContext(Context);

  // const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [loadingData, setLoadingData] = useState(false);
  const [loadingDiseasesData, setLoadingDiseasesData] = useState(false);
  const [loadingCategoriesData, setLoadingCategoriesData] = useState(false);
  const [loadingTargetsData, setLoadingTargetsData] = useState(false);

  const [diseasePageCap, setDiseasePageCap] = useState(1);
  const [targetPageCap, setTargetPageCap] = useState(1);

  const [subDiseasesData, setSubDiseasesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [subTargetsData, setSubTargetsData] = useState([]);

  const [diseaseSearchValue, setDiseaseSearchValue] = useState('');
  const [categorySearchValue] = useState('');
  const [targetSearchValue, setTargetSearchValue] = useState('');

  const [selectedDisease, setSelectedDisease] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedTarget, setSelectedTarget] = useState();

  const [frequencyValue, setFrequencyValue] = useState();
  const [dateValue, setDateValue] = useState(null);

  /*
  Function to handle frequency change
  @param {String} frequency - Selected frequency
  */
  const handleFrequencyChange = (frequency) => {
    setFrequencyValue(frequency);
  };
  /*
  Function to handle date change
  @param {Date} date - Selected date
  */
  const handleDateChange = (date) => {
    setDateValue(date);
  };

  const routes = {
    //disease routes
    subDiseasesRoute: getSubDiseasesRoute(diseasePageCap, diseaseSearchValue),
    subDiseasesWithCategoryRoute: getSubDiseasesWithCategoryRoute(
      diseasePageCap,
      diseaseSearchValue,
      selectedCategory,
    ),
    subDiseasesWithTargetRoute: getSubDiseasesWithTargetRoute(
      diseasePageCap,
      diseaseSearchValue,
      selectedTarget,
    ),
    subDiseasesWithCategoryAndTargetRoute: getSubDiseasesWithCategoryAndTargetRoute(
      diseasePageCap,
      diseaseSearchValue,
      selectedCategory,
      selectedTarget,
    ),
    //category routes
    subCategoriesRoute: getSubCategoriesRoute(categorySearchValue),
    subCategoriesWithDiseaseRoute: getSubCategoriesWithDiseaseRoute(
      categorySearchValue,
      selectedDisease,
    ),
    subCategoriesWithTargetRoute: getSubCategoriesWithTargetRoute(
      categorySearchValue,
      selectedTarget,
    ),
    subCategoriesWithDiseaseAndTargetRoute: getSubCategoriesWithDiseaseAndTargetRoute(
      categorySearchValue,
      selectedDisease,
      selectedTarget,
    ),
    //target routes
    subTargetsRoute: getSubTargetsRoute(targetPageCap, targetSearchValue),
    subTargetsWithDiseaseRoute: getSubTargetsWithDiseaseRoute(
      targetPageCap,
      targetSearchValue,
      selectedDisease,
    ),
    subTargetsWithCategoryRoute: getSubTargetsWithCategoryRoute(
      targetPageCap,
      targetSearchValue,
      selectedCategory,
    ),
    subTargetsWithDiseaseAndCategoryRoute: getSubTargetsWithDiseaseAndCategoryRoute(
      targetPageCap,
      targetSearchValue,
      selectedDisease,
      selectedCategory,
    ),
  };

  // Crafting routes
  const diseaseRoute =
    routes[
      selectedTarget && selectedCategory
        ? 'subDiseasesWithCategoryAndTargetRoute'
        : selectedTarget
        ? 'subDiseasesWithTargetRoute'
        : selectedCategory
        ? 'subDiseasesWithCategoryRoute'
        : 'subDiseasesRoute'
    ];
  const categoryRoute =
    routes[
      selectedTarget && selectedDisease
        ? 'subCategoriesWithDiseaseAndTargetRoute'
        : selectedTarget
        ? 'subCategoriesWithTargetRoute'
        : selectedDisease
        ? 'subCategoriesWithDiseaseRoute'
        : 'subCategoriesRoute'
    ];
  const targetRoute =
    routes[
      selectedCategory && selectedDisease
        ? 'subTargetsWithDiseaseAndCategoryRoute'
        : selectedDisease
        ? 'subTargetsWithDiseaseRoute'
        : selectedCategory
        ? 'subTargetsWithCategoryRoute'
        : 'subTargetsRoute'
    ];

  /*
Handler for changing the selected disease
@param disease {Object} The selected disease object
*/
  const handleDiseaseChange = (disease) => {
    setSelectedDisease(disease);
  };

  /*
  Handler for searching for a disease
  @param value {String} The value to search for
  */
  const handleDiseaseSearch = (value) => {
    setDiseasePageCap(1);
    setDiseaseSearchValue(value);
  };

  // Handler for loading more diseases
  const handleLoadMoreDiseases = () => {
    setDiseasePageCap(diseasePageCap + 1);
  };

  /*
  Handler for changing the selected category
  @param category {Object} The selected category object
  */
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  /*
  Handler for changing the selected target
  @param target {Object} The selected target object
  */
  const handleTargetChange = (target) => {
    setSelectedTarget(target);
  };

  /*
  Handler for searching for a target
  @param value {String} The value to search for
  */
  const handleTargetSearch = (value) => {
    setTargetPageCap(1);
    setTargetSearchValue(value);
  };

  // Handler for loading more targets
  const handleLoadMoreTargets = () => {
    setTargetPageCap(targetPageCap + 1);
  };

  // Creates an array of frequency options
  let frequencyOptions = selectFrequencyOptions.map((freq) => `${freq.period}|${freq.value}`);

  let therapeuticCategories = [];
  let diseases = [];
  let targets = [];

  // If subCategoriesData exists, create an array of therapeutic categories
  if (subCategoriesData) {
    therapeuticCategories = subCategoriesData.map(
      (category) => `${category.Category_id}|${category.Category_name}`,
    );
  }
  // If subDiseasesData exists, create an array of diseases
  if (subDiseasesData) {
    diseases = subDiseasesData.map((disease) => `${disease.Disease_id}|${disease.Disease_name}`);
  }

  // If subTargetsData exists, create an array of targets
  if (subTargetsData) {
    targets = subTargetsData.map(
      (target) => `${target.Target_id}|${target.Primary_accession} - ${target.Uniprot_id}`,
    );
  }

  // Fetches data from the API when the component mounts or the open state changes
  useEffect(() => {
    if (open && isMySubs) {
      setLoadingData(true);
      setLoadingDiseasesData(true);
      setLoadingCategoriesData(true);
      setLoadingTargetsData(true);
      const fetchData = async () => {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          Promise.allSettled([
            axios.get(diseaseRoute, {
              headers: authHeader(),
            }),
            axios.get(categoryRoute, {
              headers: authHeader(),
            }),
            axios.get(targetRoute, {
              headers: authHeader(),
            }),
          ])
            .then(([diseaseRes, catRes, targetRes]) => {
              const successfulResponses = [];
              const failedResponses = [];

              for (const response of [diseaseRes, catRes, targetRes]) {
                if (response.status === 'fulfilled') {
                  successfulResponses.push(response.value.data);
                } else {
                  failedResponses.push(response.reason);
                }
              }

              setSubDiseasesData(successfulResponses[0].Diseases);
              setLoadingDiseasesData(false);
              setSubCategoriesData(successfulResponses[1].Therapeutic_categories);
              setLoadingCategoriesData(false);
              setSubTargetsData(successfulResponses[2].Targets);
              setLoadingTargetsData(false);

              if (failedResponses.length > 0) {
                console.error(failedResponses);
              }
            })
            .finally(() => {
              setLoadingData(false);
            });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        }
      };
      fetchData();
    }
  }, [open]);

  /*
This useEffect is used to format the data for the date field and set the value.
@param data {Object} The data object received from the server
*/
  useEffect(() => {
    if (data) {
      const dateString = data.issueDate + '|' + data.sendTime;
      setDateValue(formatDateStringToISO(dateString));
      const matchingOption = selectFrequencyOptions.find(
        (option) => option.period === data.frequency,
      );
      if (matchingOption) {
        setFrequencyValue(`${matchingOption.period}|${matchingOption.value}`);
      }
      setSelectedDisease(data && data.disease ? `${data.diseaseID}|${data.disease}` : null);
      setSelectedCategory(data && data.therapyCat ? `${data.categoryID}|${data.therapyCat}` : null);
      setSelectedTarget(
        data && data.primary ? `${data.targetID}|${data.primary} - ${data.uniprotID}` : null,
      );
    }
  }, [data]);

  /*
This useEffect is used to set the loading and page cap state variables.
@param selectedCategory {String} The selected category ID and name
@param selectedDisease {String} The selected disease ID and name
@param selectedTarget {String} The selected target ID and name
@param diseaseSearchValue {String} The search query for diseases
@param targetSearchValue {String} The search query for targets
*/
  useEffect(() => {
    setLoadingDiseasesData(true);
    setLoadingCategoriesData(true);
    setLoadingTargetsData(true);
    setDiseasePageCap(1);
    setTargetPageCap(1);
  }, [selectedCategory, selectedDisease, selectedTarget, diseaseSearchValue, targetSearchValue]);

  /*
This useEffect is used to fetch data for diseases, categories, and targets when the search values change.
@param selectedCategory {String} The selected category ID and name
@param selectedDisease {String} The selected disease ID and name
@param selectedTarget {String} The selected target ID and name
@param diseaseSearchValue {String} The search query for diseases
@param targetSearchValue {String} The search query for targets
*/
  useEffect(() => {
    if (diseasePageCap === 1 && open) {
      const delay = 500;
      let timerId;

      const fetchData = async () => {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          Promise.allSettled([
            axios.get(diseaseRoute, {
              headers: authHeader(),
            }),
            axios.get(categoryRoute, {
              headers: authHeader(),
            }),
            axios.get(targetRoute, {
              headers: authHeader(),
            }),
          ]).then(([diseaseRes, catRes, targetRes]) => {
            const successfulResponses = [];
            const failedResponses = [];

            for (const response of [diseaseRes, catRes, targetRes]) {
              if (response.status === 'fulfilled') {
                successfulResponses.push(response.value.data);
              } else {
                failedResponses.push(response.reason);
              }
            }

            setSubDiseasesData(successfulResponses[0]?.Diseases);
            setLoadingDiseasesData(false);
            setSubCategoriesData(successfulResponses[1]?.Therapeutic_categories);
            setLoadingCategoriesData(false);
            setSubTargetsData(successfulResponses[2]?.Targets);
            setLoadingTargetsData(false);

            if (failedResponses.length > 0) {
              console.error(failedResponses);
            }
          });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        }
      };
      const clearTimer = () => {
        clearTimeout(timerId);
      };

      const debounceFetchData = () => {
        clearTimer();
        timerId = setTimeout(fetchData, delay);
      };
      debounceFetchData();
      return clearTimer;
    }
  }, [selectedCategory, selectedDisease, selectedTarget, diseaseSearchValue, targetSearchValue]);

  /*
Runs when the component mounts or when selectedDisease or diseasePageCap changes.
Fetches diseases data from the API and updates state.
*/
  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        setLoadingDiseasesData(true);
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          const response = await axios.get(diseaseRoute, { headers: authHeader() });
          const successfulDiseases = response.status === 200 ? response.data.Diseases : [];

          setSubDiseasesData((prevData) => {
            if (prevData) {
              const newData = [...prevData, ...successfulDiseases];
              if (prevData !== null) {
                for (let i = 0; i < prevData.length; i++) {
                  if (JSON.stringify(prevData[i]) !== JSON.stringify(successfulDiseases[i])) {
                    return newData;
                  }
                }
              }
              return prevData || [];
            }
          });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingDiseasesData(false);
        }
      };
      fetchData();
    }
  }, [selectedDisease, diseasePageCap]);

  /*
Runs when the component mounts or when selectedTarget or targetPageCap changes.
Fetches targets data from the API and updates state.
*/
  useEffect(() => {
    // if (open && isFirstLoad) {
    //   setIsFirstLoad(false);
    // } else if (open && !isFirstLoad) {
    if (open) {
      // setIsFirstLoad(false);
      const fetchData = async () => {
        setLoadingTargetsData(true);
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          const response = await axios.get(targetRoute, { headers: authHeader() });
          const successfulTargets = response.status === 200 ? response.data.Targets : [];

          setSubTargetsData((prevData) => {
            const newData = [...prevData, ...successfulTargets];
            if (prevData !== null) {
              for (let i = 0; i < prevData.length; i++) {
                if (JSON.stringify(prevData[i]) !== JSON.stringify(successfulTargets[i])) {
                  return newData;
                }
              }
            }
            return prevData || [];
          });
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingTargetsData(false);
        }
      };
      fetchData();
    }
  }, [selectedTarget, targetPageCap]);

  // Creates a new subscription by sending a POST request to the API with data from the form.
  const createSubscription = async () => {
    const data = {
      target_id: nameValue
        ? nameValue.split('|')[0]
        : selectedTarget
        ? selectedTarget.split('|')[0]
        : null,
      disease_id: diseaseValue
        ? diseaseValue.split('|')[0]
        : selectedDisease
        ? selectedDisease.split('|')[0]
        : null,
      therapeutic_category_id: catValue
        ? catValue.split('|')[0]
        : selectedCategory
        ? selectedCategory.split('|')[0]
        : null,
      period: frequencyValue ? frequencyValue.split('|')[0] : null,
      send_time: dateValue ? formatToDateString(dateValue).split('|')[1] : null,
      start_date: dateValue ? formatToDateString(dateValue).split('|')[0] : null,
    };
    try {
      setLoadingData(true);
      await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
      await axios.post(SUBSCRIPTIONS_URL, data, { headers: authHeader() });
      successMessage(isMySubs ? 'Subscription created' : 'Subscription created');
    } catch (error) {
      refreshFunction(error, createSubscription);
    } finally {
      setLoadingData(false);
    }
  };

  // Updates a subscription by sending a POST request to the API with data from the form.

  const updateSubscription = async (subId) => {
    const data = {
      subscription_id: subId,
      target_id: selectedTarget ? selectedTarget.split('|')[0] : null,
      disease_id: selectedDisease ? selectedDisease.split('|')[0] : null,
      therapeutic_category_id: selectedCategory ? selectedCategory.split('|')[0] : null,
      period: frequencyValue ? frequencyValue.split('|')[0] : null,
      send_time: dateValue ? formatToDateString(dateValue).split('|')[1] : null,
      start_date: dateValue ? formatToDateString(dateValue).split('|')[0] : null,
    };
    try {
      setLoadingData(true);
      await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
      await axios.put(SUBSCRIPTIONS_URL, data, { headers: authHeader() });
      successMessage('Subscription updated');
    } catch (error) {
      refreshFunction(error, updateSubscription);
    } finally {
      setLoadingData(false);
    }
  };

  //resetForm funcs
  const resetForm = () => {
    setSelectedDisease();
    setSelectedCategory();
    setSelectedTarget();
    setFrequencyValue();
    setDateValue(null);
    setOpen(false);
    if (setIsUpdateModal) {
      setIsUpdateModal(false);
    }
  };

  const handleOk = async () => {
    isUpdateModal ? await updateSubscription(data.subId) : await createSubscription();
    if (isMySubs) {
      setIsSmthNew(isSmthNew + 1);
    }
    resetForm();
  };

  const handleCancel = () => {
    resetForm();
  };

  console.log();

  return (
    <Modal
      title={isUpdateModal ? 'Set up your subscription' : 'Do you want to subscribe?'}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={isUpdateModal ? 'Subscribe' : 'Yes'}
      okButtonProps={{
        size: 'large',
        className: ['btn_antd', 'modal_btns_padding'],
        disabled: isMySubs && !selectedTarget && !selectedCategory && !selectedDisease,
      }}
      cancelButtonProps={{
        size: 'large',
        className: ['text_border_btn_antd', 'modal_btns_padding'],
      }}
      closeIcon={<ModalCloseIcon />}
    >
      {loadingData ? (
        <div style={{ height: 140 }}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {isMySubs ? (
            <>
              <div className='modal_body_section'>
                <p className='input_section_title'>Target</p>
                <SelectingComp
                  selectOptions={targets}
                  onFilterValue={handleTargetChange}
                  isShowSearch
                  isAllowClear
                  selectClassName='filter'
                  placeholder='Search list'
                  loadingData={loadingTargetsData}
                  isServerSideFunc
                  handleSearch={handleTargetSearch}
                  handleLoadMore={handleLoadMoreTargets}
                  searchValue={targetSearchValue}
                  selectDefaultValue={selectedTarget && selectedTarget.split('|')[1]}
                />
              </div>
              <div className='modal_body_section'>
                <p className='input_section_title'>Therapeutic category</p>
                <SelectingComp
                  selectOptions={therapeuticCategories}
                  onFilterValue={handleCategoryChange}
                  isShowSearch
                  isAllowClear
                  selectClassName='filter'
                  placeholder='Search list'
                  loadingData={loadingCategoriesData}
                  selectDefaultValue={selectedCategory && selectedCategory.split('|')[1]}
                />
              </div>
              <div className='modal_body_section'>
                <p className='input_section_title'>Disease</p>
                <SelectingComp
                  selectOptions={diseases}
                  onFilterValue={handleDiseaseChange}
                  isShowSearch
                  isAllowClear
                  selectClassName='filter'
                  placeholder='Search list'
                  loadingData={loadingDiseasesData}
                  isServerSideFunc
                  handleSearch={handleDiseaseSearch}
                  handleLoadMore={handleLoadMoreDiseases}
                  searchValue={diseaseSearchValue}
                  selectDefaultValue={selectedDisease && selectedDisease.split('|')[1]}
                />
              </div>
            </>
          ) : (
            <>
              {nameValue && (
                <div className='modal_body_section'>
                  <p className='section_title'>Target</p>
                  <span className='section_text'>{nameValue.split('|')[1]}</span>
                </div>
              )}
              {catValue && (
                <div className='modal_body_section'>
                  <p className='section_title'>Therapeutic category</p>
                  <span className='section_text'>{catValue.split('|')[1]}</span>
                </div>
              )}
              {diseaseValue && (
                <div className='modal_body_section'>
                  <p className='section_title'>Disease</p>
                  <span className='section_text'>{diseaseValue.split('|')[1]}</span>
                </div>
              )}
            </>
          )}
          <div className='modal_body_section'>
            <p className={isMySubs ? 'input_section_title' : 'section_title'}>Frequency</p>
            <SelectFrequencyComp
              onSelectFrequencyValue={handleFrequencyChange}
              selectFrequencyOptions={frequencyOptions}
              frequencyValue={frequencyValue}
              onDateChange={handleDateChange}
              defaultDateValue={dateValue}
              isUpdateModal={isUpdateModal}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
