import React, { useState, useEffect } from 'react';
import './transition-comp.scss';

/*
Displays formatted data depending on the data type and structure.
@param text {string|object|Array} The text data to display
*/

export const DisplayData = ({ text }) => {
  // State to hold the formatted value
  const [value, setValue] = useState(null);

  // Function to format the data based on its type
  useEffect(() => {
    if (text === null) {
      // Display '-' if data is null
      setValue('-');
    } else if (typeof text === 'string' || typeof text === 'number') {
      // Display the text or number as is
      setValue(text);
    } else if (typeof text === 'object' && !Array.isArray(text)) {
      // Format for an object with 'full_name', 'short_name', 'name', and 'Gene_name' properties
      setValue(
        text.full_name && text.short_name ? (
          <>
            {text.full_name}
            <br />
            {text.short_name}
          </>
        ) : text.name ? (
          <>
            {text.category && text.category}
            <br />
            {text.name}
          </>
        ) : text.Gene_name ? (
          text.Gene_name
        ) : (
          text.full_name || text.short_name || '-'
        ),
      );
    } else if (Array.isArray(text)) {
      // Format for an array of text or objects
      setValue(
        text.length === 0
          ? '-' // Display '-' if the array is empty
          : typeof text[0] === 'string' || typeof text[0] === 'string'
          ? text.map((item, index) => (
              <div className='desc_array_item' key={index}>
                {item || '-'}
              </div>
            ))
          : typeof text[0] === 'object'
          ? text.map((item, index) =>
              item.full_name && item.short_name ? (
                <div className='desc_array_item' key={index}>
                  {item.full_name}
                  <br />
                  {item.short_name}
                </div>
              ) : item.name ? (
                <div className='desc_array_item' key={index}>
                  {item.name}
                  {item.category && <span className='cat_wrapper'> / {item.category}</span>}
                </div>
              ) : (
                <div key={index}>{item.full_name || item.short_name || '-'}</div>
              ),
            )
          : '-', // Display '-' if the array is not of text or objects
      );
    }
  }, [text]);

  return <div>{value}</div>;
};
