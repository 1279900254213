import React from 'react';
import { Input } from 'antd';

import './form_input.scss';

/*
Create a FormInput component with the given props
@param placeholder {String} The input placeholder text
@param isPassword {Boolean} Whether the input should be type password
@param onChange {Function} The function to handle input onChange event
@param onFocus {Function} The function to handle input onFocus event
@param size {String} The size of the input ('large', 'middle', 'small')
@param bordered {Boolean} Whether the input should have a border
@param style {Object} The style object to apply to the input
@param className {String} The className to apply to the input
@param value {String} The value of the input
@param maxLength {Number} The maximum length of the input value
*/
export const FormInput = ({
  placeholder,
  isPassword,
  onChange,
  onFocus,
  size,
  bordered,
  style,
  className,
  value,
  maxLength,
}) => {
  return (
    <>
      {isPassword ? (
        <Input.Password
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus && onFocus}
          size={size ? size : 'large'}
          bordered={bordered}
          style={style}
          className={className}
          value={value && value}
          maxLength={maxLength ? maxLength : 256}
        />
      ) : (
        <Input
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus && onFocus}
          size={size ? size : 'large'}
          bordered={bordered}
          style={style}
          className={className}
          value={value && value}
          maxLength={maxLength ? maxLength : 256}
        />
      )}
    </>
  );
};
