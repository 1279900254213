import { ButtonComp } from '../UI/ButtonComp/ButtonComp';
import { SearchInput } from '../UI/SearchInput/SearchInput';

/*
A component that renders a search input box and a list of search examples
@param {String} dataSource - The value of the search input box
@param {Function} setDataSource - A function that sets the value of the search input box
@param {Function} handleOnChange - A function that handles the onChange event of the search input box
@param {Function} onSearchClick - A function that handles the onClick event of the search button
@param {Array} search_examples - An array of strings containing search examples
@returns {JSX.Element}
*/
export const SearchInputBox = ({
  dataSource,
  setDataSource,
  handleOnChange,
  onSearchClick,
  search_examples,
}) => {
  return (
    <div className='search_input_box'>
      <div className='search_input_container'>
        <SearchInput
          placeholder='Search'
          allowClear
          value={dataSource}
          onChange={handleOnChange}
          style={{
            maxWidth: 560,
            backgroundColor: '#fff',
            boxShadow: '0px 2px 20px rgba(202, 202, 224, 0.12)',
          }}
          bordered={false}
          onPressEnter={onSearchClick}
        />
        {dataSource && dataSource.length >= 3 && (
          <ButtonComp
            buttonClassName='btn_antd search_button'
            type='text'
            buttonSize='large'
            onButtonClick={onSearchClick}
          >
            Search
          </ButtonComp>
        )}
      </div>
      <SearchExamplesBox search_examples={search_examples} setDataSource={setDataSource} />
    </div>
  );
};

/*
A component that renders a list of search examples
@param {Array} search_examples - An array of strings containing search examples
@param {Function} setDataSource - A function that sets the value of the search input box
@returns {JSX.Element}
*/
const SearchExamplesBox = ({ search_examples, setDataSource }) => {
  return (
    <span className='search_examples_box'>
      Examples:&ensp;
      {search_examples &&
        search_examples.map((example, i) => {
          return (
            <span className='search_examples_container' key={i}>
              <p className='search_example' onClick={() => setDataSource(example)}>
                {example}
              </p>
              <p className='search_example_coma'>,&nbsp;</p>
            </span>
          );
        })}
    </span>
  );
};
