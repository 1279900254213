import { LoadingOutlined } from '@ant-design/icons';

export const Spinner = ({ size, position }) => (
  <LoadingOutlined
    style={{
      fontSize: size && size,
      color: '#cc33ff',
      position: position && 'absolute',
      top: position && '50%',
      margin: position && -10,
    }}
    spin
  />
);
