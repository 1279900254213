import { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Navbar } from '../components/UI/Navbar/Navbar';

import Trends from '../pages/Trends/Trends';
import Target from '../pages/Target/Target';
import GlobalSearch from '../pages/GlobalSearch/GlobalSearch';
import GlobalSearchResults from '../pages/GlobalSearchResults/GlobalSearchResults';
import MySubscriptions from '../pages/MySubscriptions/MySubscriptions';
import Login from '../pages/Auth/Login';

import { Context } from '../helpers/context';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
// Defining Router component which renders different pages based on the URL
export const Router = () => {
  // Get user data from Context
  const { user } = useContext(Context);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* Nested Route component with a PrivateRoute component as its child */}
        <Route element={<PrivateRoute user={user} />}>
          <Route path='/trends' element={<Trends />} />
          <Route path='/trends/target/:targetUniprotId' element={<Target />} />
          <Route path='/global_search' element={<GlobalSearch />} />
          <Route path='/global_search/:result' element={<GlobalSearchResults />} />
          <Route path='/my_subscriptions' element={<MySubscriptions />} />
        </Route>
        {/* Nested Route component with a PublicRoute component as its child */}
        <Route element={<PublicRoute user={user} />}>
          <Route path='/login' element={<Login />} />
        </Route>
        {/* Default route which redirects user to Trends page if logged in or Login page if not */}
        <Route path='*' element={<Navigate to={user ? '/trends' : '/login'} replace />} />
      </Routes>
    </BrowserRouter>
  );
};
