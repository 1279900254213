import { Spin } from 'antd';
import { Spinner } from '../UI/LoadingComponent/Spinner';

/*
This component is used to render a search count block, displaying a label and a count of search results
It can also display a loading spinner when data is being loaded
It takes the following props:
@param label {String} The label to display for the count block
@param count {Number} The number to display as the count
@param loading {Boolean} Whether or not data is currently being loaded, if true a loading spinner is displayed
*/
export const SearchCountBlock = ({ label, count, loading }) => {
  return (
    <span className='count_block'>
      <span>{label}</span>
      {loading ? (
        <Spin indicator={<Spinner />} />
      ) : (
        <span style={{ color: '#1C1C1C' }}>{count}</span>
      )}
    </span>
  );
};
