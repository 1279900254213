import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  getTrendsCategoriesDiseasesRelatedRoute,
  getTrendsDiseasesRoute,
  getTrendsTargetsIndexRoute,
  getTrendsTargetsIndexWithCategoryRoute,
  getTrendsTargetsIndexWithDiseaseRoute,
  getTrendsNewRoute,
  getTrendsNewWithCategoryRoute,
  getTrendsNewWithDiseaseRoute,
} from './trendsRoutes';
import { trendsColumns } from './tableColumns';

import { VALID_TOKEN_URL } from '../../helpers/config';
import { authHeader, refreshFunction } from '../../helpers/authFunctions';
import { fetchTableData } from '../../helpers/queryFunctions';
import { Context } from '../../helpers/context';

import FilteringComp from '../../components/FilteringComp/FilteringComp';
import TableComp from '../../components/Table/Table';
import { PageTitle } from '../../components/UI/PageTitle/PageTitle';
import '../../assets/scss/page_global_styles.scss';
import { getTableRelevantTargetsData, getTableNewData } from './getTableData';

/*
Component for displaying trends data and news.
Manages various states and routes to fetch and display data.
@returns {JSX.Element}
*/
const Trends = () => {
  const { refresh, refreshToken, logout } = useContext(Context);

  /*
  State variables for managing loading states and data.
  */
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingIndexTableData, setLoadingIndexTableData] = useState(false);
  const [loadingNewsTableData, setLoadingNewsTableData] = useState(false);

  /*
  State variables for managing which table is currently active and what data is displayed.
  */
  const [activeTableButton, setActiveTableButton] = useState(0);
  const [trendsData, setTrendsData] = useState();
  const [newData, setNewData] = useState();

  const [diseasesData, setDiseasesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState();

  const [filterCat, setFilterCat] = useState([]);

  const [selectedDisease, setSelectedDisease] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const [diseasePageCap, setDiseasePageCap] = useState(1);

  // State variables for managing pagination for trends targets and news.
  const [trendsTargetsIndexPageCap, setTrendsTargetsIndexPageCap] = useState(1);
  const [trendsNewPageCap, setTrendsNewPageCap] = useState(1);
  const [trendsTargetsIndexTotalCount, setTrendsTargetsIndexTotalCount] = useState();

  const [trendsNewTotalCount, setTrendsNewTotalCount] = useState();

  const [trendsEntriesPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  /*Handles the change of selected disease.
  @param {String} disease The selected disease.
  */
  const handleDiseaseChange = (disease) => {
    setSelectedDisease(disease);
  };

  /*
  Handles the change of selected category.
  @param {String} category The selected category.
  */
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  /*
  Handles the search input value.
  @param {String} value The input value.
  */
  const handleSearch = (value) => {
    setDiseasePageCap(1);
    setSearchValue(value);
  };

  // Handles the load more button on the diseases table.
  const handleLoadMore = () => {
    setDiseasePageCap(diseasePageCap + 1);
  };

  // Object with various routes to fetch data.
  const routes = {
    trendsCategoriesDiseasesRelatedRoute: getTrendsCategoriesDiseasesRelatedRoute(selectedDisease),
    trendsDiseasesRoute: getTrendsDiseasesRoute(diseasePageCap, selectedCategory, searchValue),
    targetsIndexRoute: getTrendsTargetsIndexRoute(trendsTargetsIndexPageCap, trendsEntriesPerPage),
    targetsIndexWithCategoryRoute: getTrendsTargetsIndexWithCategoryRoute(
      trendsTargetsIndexPageCap,
      trendsEntriesPerPage,
      selectedCategory,
    ),
    targetsIndexWithDiseaseRoute: getTrendsTargetsIndexWithDiseaseRoute(
      trendsTargetsIndexPageCap,
      trendsEntriesPerPage,
      selectedDisease,
    ),
    newRoute: getTrendsNewRoute(trendsNewPageCap, trendsEntriesPerPage),
    newWithCategoryRoute: getTrendsNewWithCategoryRoute(
      trendsNewPageCap,
      trendsEntriesPerPage,
      selectedCategory,
    ),
    newWithDiseaseRoute: getTrendsNewWithDiseaseRoute(
      trendsNewPageCap,
      trendsEntriesPerPage,
      selectedDisease,
    ),
  };

  //  Function sets the appropriate route based on the selected disease or category
  const trendsTargetsIndexRoute =
    routes[
      selectedDisease
        ? 'targetsIndexWithDiseaseRoute'
        : selectedCategory
        ? 'targetsIndexWithCategoryRoute'
        : 'targetsIndexRoute'
    ];

  // Function sets the appropriate route for new trends based on the selected disease or category
  const trendsNewRoute =
    routes[
      selectedDisease
        ? 'newWithDiseaseRoute'
        : selectedCategory
        ? 'newWithCategoryRoute'
        : 'newRoute'
    ];

  //  Function handles the pagination and sets the page cap for the targets index and new trends table
  const handlePaginationPageChange = (page) => {
    if (activeTableButton === 0) {
      setTrendsTargetsIndexPageCap(page);
    }
    if (activeTableButton === 1) {
      setTrendsNewPageCap(page);
    }
  };

  //Fetch initial data for Trends page
  useEffect(() => {
    setLoadingData(true);
    setLoadingIndexTableData(true);
    const fetchData = async () => {
      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        const [diseasesRes, categoriesRes, trendsRes] = await Promise.allSettled([
          axios.get(routes.trendsDiseasesRoute, { headers: authHeader() }),
          axios.get(routes.trendsCategoriesDiseasesRelatedRoute, { headers: authHeader() }),
          axios.get(routes.targetsIndexRoute, { headers: authHeader() }),
        ]);
        const resolvedResponses = [diseasesRes, categoriesRes, trendsRes].filter(
          (res) => res.status === 'fulfilled',
        );
        const rejectedReasons = [diseasesRes, categoriesRes, trendsRes]
          .filter((res) => res.status === 'rejected')
          .map((res) => res.reason);
        if (rejectedReasons.length > 0) {
          throw new Error(rejectedReasons.join(', '));
        }
        setDiseasesData(resolvedResponses[0].value.data.Diseases);
        setCategoriesData(resolvedResponses[1].value.data);
        setTrendsData(resolvedResponses[2].value.data);
        setTrendsTargetsIndexTotalCount(resolvedResponses[2].value.data.Total_count.count);
        setLoadingIndexTableData(false);
      } catch (error) {
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, []);

  // Function fetches data for diseases when the selected category, disease or search value changes
  useEffect(() => {
    setLoadingData(true);
    setDiseasePageCap(1);
  }, [selectedCategory, selectedDisease, searchValue]);

  // Function fetches data for diseases using debounce when the page cap changes
  useEffect(() => {
    if (diseasePageCap === 1 && !isFirstLoad) {
      const delay = 500;
      let timerId;

      const fetchData = async () => {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          const response = await axios.get(routes.trendsDiseasesRoute, { headers: authHeader() });
          setDiseasesData(response.data.Diseases);
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingData(false);
        }
      };
      const clearTimer = () => {
        clearTimeout(timerId);
      };

      const debounceFetchData = () => {
        clearTimer();
        timerId = setTimeout(fetchData, delay);
      };
      debounceFetchData();
      return clearTimer;
    }
  }, [selectedCategory, diseasePageCap, searchValue]);

  //filters diseases based on selectedDisease and diseasesData and updates the filterCat state variable based on the result.
  useEffect(() => {
    if (!isFirstLoad) {
      if (!selectedDisease) {
        setFilterCat([]);
        return;
      }
      setLoadingData(true);
      const filteredData = diseasesData.filter(
        (item) =>
          (!selectedDisease || item.Disease_ID + '|' + item.Disease_name === selectedDisease) &&
          (!selectedCategory ||
            item.Therapeutic_categories.some(
              (category) =>
                category.Therapeutic_category_ID + '|' + category.Therapeutic_category_name ===
                selectedCategory,
            )),
      );
      const newFilterCat =
        filteredData &&
        filteredData
          .flatMap((item) => item.Therapeutic_categories)
          .filter((item) => item.Therapeutic_category_ID && item.Therapeutic_category_name)
          .map((item) => ({
            id: item.Therapeutic_category_ID,
            name: item.Therapeutic_category_name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => `${item.id}|${item.name}`)
          .filter((item, index, arr) => arr.indexOf(item) === index);
      setFilterCat(newFilterCat);
      setLoadingData(false);
    }
  }, [diseasesData, selectedDisease]);

  // Fetches data for the two arrays and sets the relevant state variables
  useEffect(() => {
    if (!isFirstLoad) {
      const fetchData = async () => {
        setLoadingData(true);
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          const [diseasesRes, categoriesRes] = await Promise.allSettled([
            axios.get(routes.trendsDiseasesRoute, { headers: authHeader() }),
            axios.get(routes.trendsCategoriesDiseasesRelatedRoute, { headers: authHeader() }),
          ]);

          const successfulDiseases =
            diseasesRes.status === 'fulfilled' ? diseasesRes.value.data.Diseases : [];
          const successfulCategories =
            categoriesRes.status === 'fulfilled' ? categoriesRes.value.data : null;
          const failedResponses = [diseasesRes, categoriesRes]
            .filter((response) => response.status === 'rejected')
            .map((response) => response.reason);
          setDiseasesData((prevData) => {
            const newData = [...prevData, ...successfulDiseases];
            for (let i = 0; i < prevData.length; i++) {
              if (JSON.stringify(prevData[i]) !== JSON.stringify(successfulDiseases[i])) {
                return newData;
              }
            }
            return prevData;
          });
          setCategoriesData(successfulCategories);

          if (failedResponses.length > 0) {
            console.error(failedResponses);
          }
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingData(false);
        }
      };
      fetchData();
    }
  }, [selectedDisease, diseasePageCap]);

  //State variables for checking route changes
  const [checkTrendsTargetsIndexRoute, setCheckTrendsTargetsIndexRoute] =
    useState(trendsTargetsIndexRoute);
  const [checkTrendsNewRoute, setCheckTrendsNewRoute] = useState(trendsNewRoute);

  //Fetches data for the selected table button
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        if (activeTableButton === 0 && isFirstLoad) {
          setIsFirstLoad(false);
        } else if (activeTableButton === 0) {
          fetchTableData(
            trendsTargetsIndexRoute,
            checkTrendsTargetsIndexRoute,
            trendsData,
            setTrendsData,
            setCheckTrendsTargetsIndexRoute,
            setLoadingIndexTableData,
            authHeader,
            setTrendsTargetsIndexTotalCount,
          );
        }
        if (activeTableButton === 1) {
          fetchTableData(
            trendsNewRoute,
            checkTrendsNewRoute,
            newData,
            setNewData,
            setCheckTrendsNewRoute,
            setLoadingNewsTableData,
            authHeader,
            setTrendsNewTotalCount,
          );
        }
      } catch (error) {
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      }
    };
    fetchData();
  }, [trendsTargetsIndexRoute, trendsNewRoute, activeTableButton, trendsEntriesPerPage]);

  const dataRelevantTargetsTable = getTableRelevantTargetsData(trendsData && trendsData.Proteins);
  const dataNewTable = getTableNewData(newData && newData.Proteins);

  // Define two empty arrays for therapeuticCategories and diseases
  let therapeuticCategories = [];
  let diseases = [];

  // If categoriesData exists, extract therapeutic categories and format as string
  if (categoriesData) {
    therapeuticCategories =
      categoriesData.Therapeutic_categories &&
      categoriesData.Therapeutic_categories.map(
        (category) => `${category.Therapeutic_category_ID}|${category.Therapeutic_category_name}`,
      );
  }
  // If diseasesData exists, extract diseases and format as string
  if (diseasesData) {
    diseases = diseasesData.map((disease) => `${disease.Disease_ID}|${disease.Disease_name}`);
  }

  /*
  Handles button click on table navigation
  @param index {Number} Index of the clicked button
  @returns {void} 
  */
  const handleTableButtonClick = (index) => {
    setActiveTableButton(index);
  };

  // Define two state variables for the expanded rows in each table
  const [newTableExpanded, setNewTableExpanded] = useState({ expandedRowKeys: [] });
  const [targetTableExpanded, setTargetTableExpanded] = useState({ expandedRowKeys: [] });

  return (
    <div className='page'>
      <span className='page_container'>
        <PageTitle title='Trends' />
        <FilteringComp
          loadingData={loadingData}
          catValue={selectedCategory}
          onCatValue={handleCategoryChange}
          diseaseValue={selectedDisease}
          onDiseaseValue={handleDiseaseChange}
          therapyCat={filterCat.length > 0 ? filterCat : therapeuticCategories}
          disease={diseases}
          isServerSideFunc
          handleSearch={handleSearch}
          handleLoadMore={handleLoadMore}
          searchValue={searchValue}
        />
        <TableComp
          dataParentTable={
            activeTableButton === 0
              ? dataRelevantTargetsTable
              : activeTableButton === 1
              ? dataNewTable
              : null
          }
          expanded={activeTableButton === 0 ? newTableExpanded : targetTableExpanded}
          setExpanded={activeTableButton === 0 ? setNewTableExpanded : setTargetTableExpanded}
          activeButton={activeTableButton}
          setActiveButton={setActiveTableButton}
          loadingData={activeTableButton === 0 ? loadingIndexTableData : loadingNewsTableData}
          catValue={selectedCategory}
          diseaseValue={selectedDisease}
          buttonNames={['Relevant targets', 'New']}
          tableColumns={activeTableButton === 0 ? trendsColumns() : trendsColumns()}
          handleButtonClick={handleTableButtonClick}
          isTrends
          currentPaginationPage={
            activeTableButton === 0 ? trendsTargetsIndexPageCap : trendsNewPageCap
          }
          totalCount={activeTableButton === 0 ? trendsTargetsIndexTotalCount : trendsNewTotalCount}
          handlePaginationPageChange={handlePaginationPageChange}
        />
      </span>
    </div>
  );
};

export default Trends;
