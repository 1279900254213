import React from 'react';
import { getButtonPadding } from './horizontalListFunctions';
import './horizontal_list.scss';

/*
 * Renders a horizontal list of buttons
 * @param buttons {Array} An array of objects with button details
 * @param activeButton {Number} Index of the currently active button
 * @param onClick {Function} A function to handle button click event
 * @param isGlobalSearch {Boolean} Flag to determine if the component is used in global search
 * @returns {JSX.Element}
 */
export const HorizontalList = ({ buttons, activeButton, onClick, isGlobalSearch }) => {
  return (
    <span className='list_box'>
      <ul>
        {buttons &&
          buttons.map((button, i) => {
            return (
              <li
                key={button.key}
                className={activeButton === i ? 'li_active' : 'li_inactive'}
                style={{ padding: getButtonPadding(isGlobalSearch, button.aggregation) }}
                onClick={() => onClick(i)}
              >
                {button.name}
                {isGlobalSearch && <span className='response_count'>{button.aggregation}</span>}
              </li>
            );
          })}
      </ul>
    </span>
  );
};
