import { SelectingComp } from '../SelectingComp/SelectingComp';
import './select_frequency.scss';
import { DatePickerComp } from '../../UI/DatePickerComp/DatePickerComp';

/*
This component displays a container with a frequency selection dropdown, a date picker, and a timezone label.
@param frequencyValue {String} The currently selected frequency value.
@param onSelectFrequencyValue {Function} A function to be called when a frequency is selected.
@param selectFrequencyOptions {Array} An array of objects representing the frequency options.
@param onDateChange {Function} A function to be called when a date is selected.
@param defaultDateValue {Moment} The default value for the date picker.
@param isUpdateModal {Boolean} A flag to indicate if the component is being used in an update modal.
*/
export const SelectFrequencyComp = ({
  frequencyValue,
  onSelectFrequencyValue,
  selectFrequencyOptions,
  onDateChange,
  defaultDateValue,
  isUpdateModal,
}) => {
  return (
    <span className='selecting_container'>
      <SelectingComp
        selectValue={frequencyValue}
        onFilterValue={onSelectFrequencyValue}
        selectOptions={selectFrequencyOptions}
        selectClassName='selected_text'
        selectStyles={{ width: '130px' }}
        placeholder='Frequency'
      />
      <DatePickerComp
        placement='bottomLeft'
        onOk={onDateChange}
        showTime
        defaultDateValue={defaultDateValue}
        disabledDates
        disabled={isUpdateModal && isUpdateModal}
      />

      <p className='selecting_section_title'>GMT-8</p>
    </span>
  );
};
