import { formatRevertDashDate } from '../../helpers/formatDates';

// This function takes in an array of patent data and formats it for display in a table
export const getPatentTableData = (patentsData) => {
  let dataPatentTable = [];
  if (patentsData) {
    try {
      for (let i = 0; i < patentsData.length; ++i) {
        const patentLink =
          patentsData[i].Application_number + '|' + patentsData[i].Patent_meta.Link;
        dataPatentTable.push({
          key: patentsData[i].ID,
          // id: patentsData[i].ID,
          name: patentsData[i].Title.replace(/^1\. /, ''),
          dateField: formatRevertDashDate(
            patentsData[i].Application_date && patentsData[i].Application_date,
          ),
          dateIssued: formatRevertDashDate(patentsData[i].Grant_date && patentsData[i].Grant_date),
          patentNumber: patentsData[i].Grant_number_fk && patentsData[i].Grant_number_fk,
          applicationNumber: patentLink,
          childData: {
            abstract: patentsData[i].Patent_meta.Abstract,
            assignees: patentsData[i].Patent_meta.Applicant,
            inventors: patentsData[i].Patent_meta.Investors,
            link: patentsData[i].Patent_meta.Link,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  return dataPatentTable;
};

// This function takes in an array of paper data and formats it for display in a table
export const getPaperTableData = (papersData) => {
  let dataPapersTable = [];
  if (papersData) {
    try {
      for (let i = 0; i < papersData.length; ++i) {
        dataPapersTable.push({
          key: papersData[i].ID,
          doi: papersData[i].DOI,
          name: papersData[i].Article.article.articletitle.replace(/<\/?(?:(?!title)[^>])+>/g, ''),
          publicationDate: formatRevertDashDate(
            papersData[i].DateCompleted && papersData[i].DateCompleted,
          ),
          author: papersData[i].Article.article.author,
          childData: {
            // Has_full_text_pdf: papersData[i].Has_full_text_pdf,
            DOI: papersData[i].DOI,
            journal: papersData[i].journal_name,
            ...papersData[i].Article.article,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  return dataPapersTable;
};

// This function takes in an array of financing data and formats it for display in a table
export const getFinancingTableData = (financingData) => {
  let dataFinancingTable = [];
  if (financingData) {
    try {
      for (let i = 0; i < financingData.length; ++i) {
        dataFinancingTable.push({
          key: financingData[i].ID,
          name: financingData[i].Title,
          date: formatRevertDashDate(
            financingData[i].Publication_date && financingData[i].Publication_date,
          ),
          resource: financingData[i].Link,
          childData: {
            abstract: financingData[i].Full_text,
            link: financingData[i].Link,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  return dataFinancingTable;
};
