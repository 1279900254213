import { TARGET_PRIMARY_URL, TARGET_URL } from '../../helpers/config';
import { getQueryParam } from '../../helpers/queryFunctions';

//routes with params for data from server in Target page
export const getTargetID = (targetUniprotId) => `${TARGET_PRIMARY_URL}/${targetUniprotId}`;

export const getTargetRoute = (targetId) => `${TARGET_URL}/${targetId}`;

export const getTargetRelatedDiseasesRoute = (targetId) => `${TARGET_URL}/${targetId}/diseases`;

export const getTargetIndexPeriodRoute = (targetId, indexPeriodFrom, indexPeriodTo) =>
  `${TARGET_URL}/${targetId}/index?period_from=${indexPeriodFrom}&period_to=${indexPeriodTo}`;

export const getTargetIndexDiseaseRoute = (
  targetId,
  indexPeriodFrom,
  indexPeriodTo,
  selectedDisease,
) =>
  `${TARGET_URL}/${targetId}/index?period_from=${indexPeriodFrom}&period_to=${indexPeriodTo}&${getQueryParam(
    selectedDisease,
    'disease',
  )}`;

export const getTargetIndexCategoryRoute = (
  targetId,
  indexPeriodFrom,
  indexPeriodTo,
  selectedCategory,
) =>
  `${TARGET_URL}/${targetId}/index?period_from=${indexPeriodFrom}&period_to=${indexPeriodTo}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}`;

export const getTargetPublicationRoute = (targetId) => `${TARGET_URL}/${targetId}/trends`;

export const getTargetPublicationDiseaseRoute = (targetId, selectedDisease) =>
  `${TARGET_URL}/${targetId}/trends?${getQueryParam(selectedDisease, 'disease')}`;

export const getTargetPublicationCategoryRoute = (targetId, selectedCategory) =>
  `${TARGET_URL}/${targetId}/trends?${getQueryParam(selectedCategory, 'therapeutic')}`;

export const getTargetPatentsRoute = (targetId, pageCap, searchTableData) => {
  return `${TARGET_URL}/${targetId}/patents?page=${pageCap}&search=${searchTableData}`;
};

export const getTargetPatentsDiseaseRoute = (targetId, pageCap, searchTableData, selectedDisease) =>
  `${TARGET_URL}/${targetId}/patents?page=${pageCap}&search=${searchTableData}&${getQueryParam(
    selectedDisease,
    'disease',
  )}`;

export const getTargetPatentsCategoryRoute = (
  targetId,
  pageCap,
  searchTableData,
  selectedCategory,
) =>
  `${TARGET_URL}/${targetId}/patents?page=${pageCap}&search=${searchTableData}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}`;

export const getTargetPapersRoute = (targetId, pageCap, searchTableData) =>
  `${TARGET_URL}/${targetId}/papers?page=${pageCap}&search=${searchTableData}`;

export const getTargetPapersDiseaseRoute = (targetId, pageCap, searchTableData, selectedDisease) =>
  `${TARGET_URL}/${targetId}/papers?page=${pageCap}&search=${searchTableData}&${getQueryParam(
    selectedDisease,
    'disease',
  )}`;

export const getTargetPapersCategoryRoute = (
  targetId,
  pageCap,
  searchTableData,
  selectedCategory,
) =>
  `${TARGET_URL}/${targetId}/papers?page=${pageCap}&search=${searchTableData}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}`;

export const getTargetFinancingRoute = (targetId, pageCap, searchTableData) =>
  `${TARGET_URL}/${targetId}/news?page=${pageCap}&search=${searchTableData}`;

export const getTargetFinancingDiseaseRoute = (
  targetId,
  pageCap,
  searchTableData,
  selectedDisease,
) =>
  `${TARGET_URL}/${targetId}/news?page=${pageCap}&search=${searchTableData}&${getQueryParam(
    selectedDisease,
    'disease',
  )}`;

export const getTargetFinancingCategoryRoute = (
  targetId,
  pageCap,
  searchTableData,
  selectedCategory,
) =>
  `${TARGET_URL}/${targetId}/news?page=${pageCap}&search=${searchTableData}&${getQueryParam(
    selectedCategory,
    'therapeutic',
  )}`;
