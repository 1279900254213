import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { REFRESH_URL } from './config';
import { errorMessage } from './messageUtils';

/*
Returns the authorization header containing the access token.
@returns {Object} Authorization header
*/
export function authHeader() {
  const token = secureLocalStorage.getItem('accessToken');

  if (token) {
    return { authorization: `Bearer ${JSON.parse(token)}` };
  } else {
    return {};
  }
}

/*
Returns the authorization header containing the refresh token.
@returns {Object} Authorization header
*/
export function refreshHeader() {
  const token = secureLocalStorage.getItem('refreshToken');

  if (token) {
    return { authorization: `Bearer ${JSON.parse(token)}` };
  } else {
    return {};
  }
}
/*
Sends a request to the server to refresh the access token using the refresh token.
@returns {Promise} Promise object that resolves to the response data or rejects with an error
*/
export async function refreshToken() {
  try {
    const response = await axios.post(REFRESH_URL, {
      headers: refreshHeader(),
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

// This function is called when the API request fails with a 404 error
export const refreshFunction = async (error, fetchData, refreshToken, refresh, logout) => {
  const errorCRUD = error.response && error.response.data && error.response.data.detail;
  const expiredToken =
    error.response &&
    error.response.status === 404 &&
    error.response.data.detail === 'Token not found';

  if (expiredToken) {
    try {
      const response = await axios.post(REFRESH_URL, '', {
        headers: { authorization: `Bearer ${refreshToken}` },
      });
      refresh(response.data);
      fetchData();
    } catch (error) {
      logoutFunction(error, logout);
    }
  } else {
    errorMessage(errorCRUD ? error.response.data.detail : 'An unexpected error occurred');
    console.error(error);
  }
};

// This function is called when the API request fails with an error other than a 404 error
const logoutFunction = (error, logout) => {
  if (
    error.response &&
    error.response.status === 404 &&
    error.response.data.detail === 'Token not found'
  ) {
    logout();
  } else {
    console.error(error);
  }
};
