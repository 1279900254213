import React from 'react';
import { Link } from 'react-router-dom';

import { RenderRightArrayItem } from '../Table/DescriptionTable/RenderRightArrayItem';

import { ReactComponent as CardCloseIcon } from '../../assets/icons/closeCard.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

import { formatFromDateTimeToString } from '../../helpers/formatDates';

import './result_info_card.scss';

/*
This component renders a card containing information about a certain item.
The item's data is passed as a prop, as well as a callback function to be executed when the user clicks on the card.
*/
export const ResultInfoCard = ({ data, onDataClick }) => {
  return (
    <div className='info_card_container'>
      <CardCloseIcon onClick={() => onDataClick(null)} className='cross' />
      <div className='info_card_description_container'>
        {/* card title */}
        <span className='info_card_title'>
          {data.tag === 'Patent' && data.applicationNumber && data.applicationNumber + ' - '}
          {data.title || '-'}
        </span>
        <div className='link_container'>
          {data.tag === 'Patent' || data.tag === 'Financing' ? (
            <a
              className={data.link ? 'source_link_global' : 'source_link_global_disable'}
              href={data.link}
              target='_blank'
              rel='noreferrer'
            >
              Read in source
              <LinkIcon stroke={data.link ? '#cc33ff' : '#bab3bc'} />
            </a>
          ) : data.tag === 'Article' ? (
            <a
              className={data.DOI ? 'source_link_global' : 'source_link_global_disable'}
              href={`https://dx.doi.org/${data.DOI}`}
              target='_blank'
              rel='noreferrer'
            >
              Read in source
              <LinkIcon stroke={data.DOI ? '#cc33ff' : '#bab3bc'} />
            </a>
          ) : data.tag === 'Target' ? (
            <Link
              className={data.uniprotId ? 'source_link_global' : 'source_link_global_disable'}
              to={`/trends/target/${data.uniprotId}`}
            >
              Target
              <LinkIcon stroke={data.uniprotId ? '#cc33ff' : '#bab3bc'} />
            </Link>
          ) : // <p
          //   className={data.uniprotId ? 'source_link_global' : 'source_link_global_disable'}
          //   onClick={() => navigate(`/trends/target/${data.uniprotId}`)}
          // >
          //   Target
          //   <LinkIcon stroke={data.uniprotId ? '#cc33ff' : '#bab3bc'} />
          // </p>
          null}
        </div>
        {/* card desc */}
        <span className='info_card_description'>
          <span className='description_title'>
            {data.tag === 'Patent' || data.tag === 'Article'
              ? 'Abstract'
              : data.tag === 'Financing'
              ? 'Full text'
              : data.tag === 'Target'
              ? 'Gene Symbol'
              : null}
          </span>
          <span className='description_text'>
            {data.tag === 'Patent' || data.tag === 'Article'
              ? data.abstract || '-'
              : data.tag === 'Financing'
              ? data.fullText || '-'
              : data.tag === 'Target'
              ? data.geneSymbol || '-'
              : null}
          </span>
        </span>
        {data.tag === 'Patent' ? (
          <>
            <span className='info_card_description'>
              <span className='description_title'>Assignees</span>
              <span className='description_text'>{data.assignees || '-'}</span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Inventors</span>
              <span className='description_text'>{data.inventors || '-'}</span>
            </span>
            <span className='info_card_description description_row_container'>
              <span className='row_card'>
                <span className='description_title'>Date filed</span>
                <span className='description_text'>
                  {formatFromDateTimeToString(data.dateField)}
                </span>
              </span>
              <span className='row_card'>
                <span className='description_title'>Date issued</span>
                <span className='description_text'>
                  {formatFromDateTimeToString(data.dateIssue)}
                </span>
              </span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Type</span>
              <span className='description_text'>{data.type || '-'}</span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Relations</span>
              {data.relations && data.relations.length > 0
                ? data.relations.map((relation, index) => (
                    <span key={index} className='description__relations_text'>
                      <span className='relations_uniprot'>
                        <Link
                          className={
                            relation.uniprot_id
                              ? 'source_link_global'
                              : 'source_link_global_disable'
                          }
                          to={`/trends/target/${relation.uniprot_id}`}
                        >
                          {`${relation.targetName} ${relation.uniprot_id}`}
                        </Link>
                      </span>
                      {/* <span>&#9679;</span> */}
                      <span className='relations_disease'>
                        {/* {relation.diseases} */}
                        <RenderRightArrayItem isGlobalSearch arrayItem={relation.diseases} />
                      </span>
                    </span>
                  ))
                : '-'}
            </span>
          </>
        ) : data.tag === 'Article' ? (
          <>
            <span className='info_card_description description_row_container'>
              <span className='row_card' style={{ width: '50%' }}>
                <span className='description_title'>Author</span>
                <span className='description_text'>{data.author || '-'}</span>
              </span>
              <span className='row_card' style={{ width: '50%' }}>
                <span className='description_title'>Co-authors</span>
                <span className='description_text'>
                  <RenderRightArrayItem isGlobalSearch arrayItem={data.coAuthors} />
                </span>
              </span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Journal</span>
              <span className='description_text'>{data.journal || '-'}</span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Publication Date</span>
              <span className='description_text'>
                {formatFromDateTimeToString(data.publicationDate)}
              </span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>DOI</span>
              <span className='description_text'>
                <a
                  className={data.DOI ? 'source_link_global' : 'source_link_global_disable'}
                  href={`https://dx.doi.org/${data.DOI}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {data.DOI || '-'}
                </a>
              </span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Relations</span>
              {data.relations && data.relations.length > 0
                ? data.relations.map((relation, index) => (
                    <span key={index} className='description__relations_text'>
                      <span className='relations_uniprot'>
                        <Link
                          className={
                            relation.uniprot_id
                              ? 'source_link_global'
                              : 'source_link_global_disable'
                          }
                          to={`/trends/target/${relation.uniprot_id}`}
                        >
                          {`${relation.targetName} ${relation.uniprot_id}`}
                        </Link>
                        {/* <p
                          className={
                            relation.uniprot_id
                              ? 'source_link_global'
                              : 'source_link_global_disable'
                          }
                          onClick={() => navigate(`/trends/target/${relation.uniprot_id}`)}
                        >
                          {relation.targetName + ' ' + relation.uniprot_id}
                        </p> */}
                      </span>
                      {/* <span>&#9679;</span> */}
                      <span className='relations_disease'>
                        {/* {relation.diseases} */}
                        <RenderRightArrayItem isGlobalSearch arrayItem={relation.diseases} />
                      </span>
                    </span>
                  ))
                : '-'}
            </span>
          </>
        ) : data.tag === 'Target' ? (
          <>
            <span className='info_card_description description_row_container'>
              <span className='row_card'>
                <span className='description_title'>Articles</span>
                <span className='description_text'>{data.articlesAmount || '-'}</span>
              </span>
              <span className='row_card'>
                <span className='description_title'>Patents</span>
                <span className='description_text'>{data.patentsAmount || '-'}</span>
              </span>
              <span className='row_card'>
                <span className='description_title'>Financing</span>
                <span className='description_text'>{data.financingAmount || '-'}</span>
              </span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Therapeutic categories</span>
              <RenderRightArrayItem isGlobalSearch arrayItem={data.categories} collapse />
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Diseases</span>
              <RenderRightArrayItem isGlobalSearch arrayItem={data.diseases} collapse />
            </span>
          </>
        ) : data.tag === 'Financing' ? (
          <>
            <span className='info_card_description'>
              <span className='description_title'>Company</span>
              <span className='description_text'>{data.company || '-'}</span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Amount</span>
              <span className='description_text'>{data.amount || '-'}</span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Date</span>
              <span className='description_text'>
                {formatFromDateTimeToString(data.publicationDate)}
              </span>
            </span>
            <span className='info_card_description'>
              <span className='description_title'>Resource</span>
              <span className='description_text'>
                <a
                  className={data.link ? 'source_link_global' : 'source_link_global_disable'}
                  href={data.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  {data.link ? data.link.replace(/^(https?:\/\/)?(www\.)?/, '').split('/')[0] : '-'}
                </a>
              </span>
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
};
