import React, { useState } from 'react';
import TableComp from '../Table/Table';
import { patentColumns, articleColumns, financingColumns } from './tableColumns';
import { getPatentTableData, getPaperTableData, getFinancingTableData } from './getTableData';
import { ErrorBoundary } from '../../helpers/ErrorBoundary';
import '../../assets/scss/page_global_styles.scss';

/*
This component displays "Other data" table which includes patents, articles, and financing data.
It takes in several props such as patentsData, papersData, financingData, etc.
It also sets up error handling with the ErrorMsg function and uses the ErrorBoundary component to catch any errors.
The getPatentTableData, getPaperTableData, and getFinancingTableData functions are called with their respective data to format and retrieve table data.
useState is used to set and manage expanded rows for each of the tables.
The TableComp component is used to render the table with data and appropriate columns, buttons, pagination, and search functionality.
@param patentsData {Array} Array of patent data objects
@param papersData {Array} Array of article data objects
@param financingData {Array} Array of financing data objects
@param activeButton {Number} Index of the currently active button
@param setActiveButton {Function} Function to set the active button
@param handleButtonClick {Function} Function to handle button clicks
@param handleOnSearchChange {Function} Function to handle search input change
@param loadingData {Boolean} Flag to indicate whether data is loading or not
@param currentPaginationPage {Number} Index of the current page being displayed
@param totalCount {Number} Total number of data items being displayed
@param handlePaginationPageChange {Function} Function to handle pagination page changes
@returns {JSX.Element} The OtherDataTable component
*/
export const OtherDataTable = ({
  patentsData,
  papersData,
  financingData,
  activeButton,
  setActiveButton,
  handleButtonClick,
  handleOnSearchChange,
  loadingData,
  currentPaginationPage,
  totalCount,
  handlePaginationPageChange,
}) => {
  const ErrorMsg = (error) => {
    return (
      <div>
        <p>Something went wrong!</p>
        <p>{error.message}</p>
      </div>
    );
  };
  const dataPatentTable = getPatentTableData(patentsData && patentsData);
  const dataPapersTable = getPaperTableData(papersData && papersData);
  const dataFinancingTable = getFinancingTableData(financingData && financingData);

  const [patentExpanded, setPatentExpanded] = useState({ expandedRowKeys: [] });
  const [articleExpanded, setArticleExpanded] = useState({ expandedRowKeys: [] });
  const [financingExpanded, setFinancingExpanded] = useState({ expandedRowKeys: [] });

  return (
    <span className='chart_publication_title'>
      <p className='chart_header'>Other data</p>
      <ErrorBoundary ErrorComponent={ErrorMsg}>
        <TableComp
          dataParentTable={
            activeButton === 0
              ? dataPatentTable
              : activeButton === 1
              ? dataPapersTable
              : activeButton === 2
              ? dataFinancingTable
              : null
          }
          expanded={
            activeButton === 0
              ? patentExpanded
              : activeButton === 1
              ? articleExpanded
              : activeButton === 2
              ? financingExpanded
              : null
          }
          setExpanded={
            activeButton === 0
              ? setPatentExpanded
              : activeButton === 1
              ? setArticleExpanded
              : activeButton === 2
              ? setFinancingExpanded
              : null
          }
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          loadingData={loadingData}
          buttonNames={['Patents', 'Articles', 'Financing']}
          tableColumns={
            activeButton === 0
              ? patentColumns
              : activeButton === 1
              ? articleColumns
              : activeButton === 2
              ? financingColumns
              : null
          }
          handleButtonClick={handleButtonClick}
          isSearchInTable
          handleOnSearchChange={handleOnSearchChange}
          isTarget
          currentPaginationPage={currentPaginationPage}
          totalCount={totalCount}
          handlePaginationPageChange={handlePaginationPageChange}
        />
      </ErrorBoundary>
    </span>
  );
};
