/*
Return an array of objects with fields to be displayed in the tooltip for publication chart based on value and data
@param value {Number} The value to determine the set of fields to return
@param data {Array} The data array to extract payload values from
@returns {Array} The array of objects with label and value fields
*/
export const getTooltipPublicationFields = (value, data) => {
  const fields1 = [
    { label: 'Date', value: data[0].payload.Year },
    { label: 'Amount', value: data[0].payload.Amount },
    { label: 'Drug_Name', value: data[0].payload.Drug_Name },
  ];

  const fields2 = [
    { label: 'Date', value: data[0].payload.Year },
    { label: 'Amount', value: data[0].payload.Amount },
    { label: 'Drug_Name', value: data[0].payload.Drug_Name },
  ];

  const fields3 = [
    { label: 'Date', value: data[0].payload.Year },
    { label: 'Amount', value: data[0].payload.Amount },
    { label: 'Drug_Name', value: data[0].payload.Drug_Name },
    { label: 'N/A', value: data[0].payload['N/A'] },
    { label: 'Q1', value: data[0].payload.Q1 },
    { label: 'Q2', value: data[0].payload.Q2 },
    { label: 'Q3', value: data[0].payload.Q3 },
    { label: 'Q4', value: data[0].payload.Q4 },
  ];

  if (value === 1) {
    return fields1;
  } else if (value === 2) {
    return fields2;
  } else if (value === 3) {
    return fields3;
  } else {
    return [];
  }
};

/*
Return an array of objects with fields to be displayed in the tooltip for index chart based on data
@param data {Array} The data array to extract payload values from
@returns {Array} The array of objects with label and value fields
*/
export const getTooltipIndexFields = (data) => {
  const fields = [
    { label: 'Date:', value: data[0].payload.Evaluation_date },
    { label: 'Index:', value: data[0].payload.Relevant_index },
  ];

  return fields || [];
};
