import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Pagination } from 'antd';
import axios from 'axios';

import { getSearchRoute } from './globalSearchRoutes';
import {
  getTrendsCategoriesDiseasesRelatedRoute,
  getTrendsDiseasesRoute,
} from '../Trends/trendsRoutes';

import { LoadingSpinner } from '../../components/UI/LoadingComponent/LoadingComponent';
import { ResultCard } from '../../components/ResultCard/ResultCard';
import { ResultInfoCard } from '../../components/ResultInfoCard/ResultInfoCard';
import { HorizontalList } from '../../components/UI/HorizontalList/HorizontalList';
import { SearchInput } from '../../components/UI/SearchInput/SearchInput';
import { ButtonComp } from '../../components/UI/ButtonComp/ButtonComp';
import { FilteringSearchResultsComp } from '../../components/FilteringSearchResultsComp/FilteringSearchResultsComp';

import { VALID_TOKEN_URL } from '../../helpers/config';
import { authHeader, refreshFunction } from '../../helpers/authFunctions';
import { Context } from '../../helpers/context';

import { ReactComponent as OpenFilterIcon } from '../../assets/icons/filterIcons/plus.svg';
import { ReactComponent as CloseFilterIcon } from '../../assets/icons/filterIcons/close.svg';
import { ReactComponent as EmptyBoxIcon } from '../../assets/icons/emptyBox.svg';

import './global_search_results.scss';
import { getResultCard } from './getResultCards';

const GlobalSearchResults = () => {
  //___SearchValue functions
  const navigate = useNavigate();
  const { result } = useParams();
  const { refresh, refreshToken, logout } = useContext(Context);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  //___HorizontalList states
  const [isActive, setIsActive] = useState({ 0: true, 1: true, 2: true, 3: true, 4: true });
  const [activeButton, setActiveButton] = useState(0);

  const [globalSearchData, setGlobalSearchData] = useState();
  const [aggregationsData, setAggregationsData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [resultValue, setResultValue] = useState(result);
  const [newResultValue, setNewResultValue] = useState(result);

  const [pathVariable, setPathVariable] = useState('_search');
  const [textSearchVar, setTextSearchVar] = useState('or');

  //set textSearchVar value from input
  const onTextSearchVarChange = (e) => {
    setTextSearchVar(e.target.value);
  };

  //filter values
  const [diseasesData, setDiseasesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState();
  const [filterCat, setFilterCat] = useState([]);

  const [selectedDisease, setSelectedDisease] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [diseasePageCap, setDiseasePageCap] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const [dateRange, setDateRange] = useState(['', '']);

  //set dateRange value from (date is important props, which can't be removed)
  const handleDateChange = (date, dateString) => {
    setDateRange(dateString);
  };

  //___Pagination functions
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  //loading states
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDiseasesData, setLoadingDiseasesData] = useState(false);

  //routes with params on this page
  const routes = {
    searchRoute: getSearchRoute(pathVariable),
    trendsCategoriesDiseasesRelatedRoute: getTrendsCategoriesDiseasesRelatedRoute(selectedDisease),
    trendsDiseasesRoute: getTrendsDiseasesRoute(diseasePageCap, selectedCategory, searchValue),
  };

  useEffect(() => {
    setLoadingData(true);
    const fetchData = async () => {
      const data = {
        page: currentPage,
        size: itemsPerPage,
        query: resultValue,
        operator: textSearchVar,
        disease_id: selectedDisease ? Number(selectedDisease.split('|')[0]) : null,
        therapeutic_category_id: selectedCategory ? Number(selectedCategory.split('|')[0]) : null,
        doc_date_from: dateRange[0],
        doc_date_to: dateRange[1],
      };

      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        const response = await axios.post(routes.searchRoute, data, {
          headers: authHeader(),
        });
        setGlobalSearchData(response.data);
        setAggregationsData(response.data.aggregations);
        setTotalCount(response.data.hits_value);
      } catch (error) {
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, [
    resultValue, // search query
    pathVariable, // selected button (articles/patents)
    textSearchVar, // switch or/and
    selectedDisease, // selected disease
    selectedCategory, // selected category
    dateRange, // date range selected
    currentPage, // selected page
  ]);

  // Use effect to set search result value in local storage
  useEffect(() => {
    localStorage.setItem('resultValue', newResultValue);
  }, [newResultValue]);

  // Use effect to set search path based on active button
  useEffect(() => {
    if (activeButton === 0) {
      setPathVariable('_search');
    } else if (activeButton === 1) {
      setPathVariable('patents/_search');
    } else if (activeButton === 2) {
      setPathVariable('papers/_search');
    } else if (activeButton === 3) {
      setPathVariable('proteins/_search');
    } else if (activeButton === 4) {
      setPathVariable('finances/_search');
    }
  }, [activeButton]);

  // Function to handle search input change
  const handleOnChange = async (e) => {
    setNewResultValue(e.target.value);
  };

  // Function to handle search button click
  const onSearchClick = () => {
    setCurrentPage(1);
    setResultValue(newResultValue);
    newResultValue && navigate(`/global_search/${newResultValue}`);
  };

  // State and function to handle selected data
  const [selectedData, setSelectedData] = useState(null);
  const handleCardClick = (data) => {
    if (data && selectedData && data.id === selectedData.id) return;
    setSelectedData(data);
  };

  useEffect(() => {
    setSelectedData(null);
  }, [loadingData]);

  //Toggles the display of the filter modal
  const [isFilterShown, setFilterShown] = useState(false);
  const showModal = () => {
    setFilterShown(!isFilterShown);
  };

  /*
  Updates the selected disease state when a new disease is selected from the dropdown
  @param disease {Object} The selected disease
  */
  const handleDiseaseChange = (disease) => {
    setSelectedDisease(disease);
  };

  /*
  Updates the selected category state when a new category is selected from the dropdown
  @param category {Object} The selected category
  */
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  /*
  Updates the search value state when a search query is submitted
  @param value {String} The search query
  */
  const handleSearch = (value) => {
    setDiseasePageCap(1);
    setSearchValue(value);
  };

  // Increases the disease page cap state by 1 to load more diseases
  const handleLoadMore = () => {
    setDiseasePageCap(diseasePageCap + 1);
  };

  /*
  Sorts the global search data and returns it
  @param data {Object} The global search data to sort
  @returns {Object} The sorted global search data
  */
  const sortedData = getResultCard(globalSearchData && globalSearchData);

  // Fetches the initial diseases and categories data and sets the corresponding states
  useEffect(() => {
    setLoadingDiseasesData(true);
    const fetchData = async () => {
      try {
        await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
        const [diseasesRes, categoriesRes] = await Promise.allSettled([
          axios.get(routes.trendsDiseasesRoute, { headers: authHeader() }),
          axios.get(routes.trendsCategoriesDiseasesRelatedRoute, { headers: authHeader() }),
        ]);
        const resolvedResponses = [diseasesRes, categoriesRes].filter(
          (res) => res.status === 'fulfilled',
        );
        const rejectedReasons = [diseasesRes, categoriesRes]
          .filter((res) => res.status === 'rejected')
          .map((res) => res.reason);
        if (rejectedReasons.length > 0) {
          throw new Error(rejectedReasons.join(', '));
        }
        setDiseasesData(resolvedResponses[0].value.data.Diseases);
        setCategoriesData(resolvedResponses[1].value.data);
      } catch (error) {
        refreshFunction(error, fetchData, refreshToken, refresh, logout);
      } finally {
        setLoadingDiseasesData(false);
      }
    };
    fetchData();
  }, []);

  // Fetches data when the selected category, selected disease, or search value state changes
  useEffect(() => {
    setLoadingDiseasesData(true);
    setDiseasePageCap(1);
  }, [selectedCategory, selectedDisease, searchValue]);

  // Fetches data when the disease page cap, selected category, or search value state changes
  useEffect(() => {
    if (diseasePageCap === 1 && !isFirstLoad) {
      const delay = 500;
      let timerId;
      const fetchData = async () => {
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          const response = await axios.get(routes.trendsDiseasesRoute, { headers: authHeader() });
          setDiseasesData(response.data.Diseases);
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingDiseasesData(false);
        }
      };
      const clearTimer = () => {
        clearTimeout(timerId);
      };

      const debounceFetchData = () => {
        clearTimer();
        timerId = setTimeout(fetchData, delay);
      };
      debounceFetchData();
      return clearTimer;
    }
  }, [selectedCategory, diseasePageCap, searchValue]);

  /*
  Use effect to filter the disease data based on the selected disease and category.
  Also, update the filtered categories and set the loading state of diseasesData.
  @param diseasesData {Array} The array of diseases to filter
  @param selectedDisease {String} The selected disease to filter by
  @param selectedCategory {String} The selected category to filter by
  @param setFilterCat {Function} The function to update the filtered categories
  @param setLoadingDiseasesData {Function} The function to set the loading state of diseasesData
  @param isFirstLoad {Boolean} The flag indicating whether the component is first loaded
 */
  useEffect(() => {
    if (!isFirstLoad) {
      if (!selectedDisease) {
        setFilterCat([]);
        return;
      }
      setLoadingDiseasesData(true);
      const filteredData = diseasesData.filter(
        (item) =>
          (!selectedDisease || item.Disease_ID + '|' + item.Disease_name === selectedDisease) &&
          (!selectedCategory ||
            item.Therapeutic_categories.some(
              (category) =>
                category.Therapeutic_category_ID + '|' + category.Therapeutic_category_name ===
                selectedCategory,
            )),
      );
      const newFilterCat =
        filteredData &&
        filteredData
          .flatMap((item) => item.Therapeutic_categories)
          .filter((item) => item.Therapeutic_category_ID && item.Therapeutic_category_name)
          .map((item) => ({
            id: item.Therapeutic_category_ID,
            name: item.Therapeutic_category_name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => `${item.id}|${item.name}`)
          .filter((item, index, arr) => arr.indexOf(item) === index);
      setFilterCat(newFilterCat);
      setLoadingDiseasesData(false);
    }
  }, [diseasesData, selectedDisease]);

  /*
  Use effect to fetch the data on component load.
  Also, update the diseases and categories data.
  @param isFirstLoad {Boolean} The flag indicating whether the component is first loaded
  @param setIsFirstLoad {Function} The function to set the flag indicating whether the component is first loaded
  @param setLoadingDiseasesData {Function} The function to set the loading state of diseasesData
  @param authHeader {Function} The function to get the authorization header
  @param routes {Object} The object containing the routes for the API endpoints
  @param setDiseasesData {Function} The function to update the diseases data
  @param setCategoriesData {Function} The function to update the categories data
  @param refreshFunction {Function} The function to refresh the API call on error
  @param selectedDisease {String} The selected disease to filter by
  @param diseasePageCap {Number} The limit of diseases to fetch
 */
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      const fetchData = async () => {
        setLoadingDiseasesData(true);
        try {
          await axios.get(VALID_TOKEN_URL, { headers: authHeader() });
          const [diseasesRes, categoriesRes] = await Promise.allSettled([
            axios.get(routes.trendsDiseasesRoute, { headers: authHeader() }),
            axios.get(routes.trendsCategoriesDiseasesRelatedRoute, { headers: authHeader() }),
          ]);

          const successfulDiseases =
            diseasesRes.status === 'fulfilled' ? diseasesRes.value.data.Diseases : [];
          const successfulCategories =
            categoriesRes.status === 'fulfilled' ? categoriesRes.value.data : null;
          const failedResponses = [diseasesRes, categoriesRes]
            .filter((response) => response.status === 'rejected')
            .map((response) => response.reason);
          setDiseasesData((prevData) => {
            const newData = [...prevData, ...successfulDiseases];
            for (let i = 0; i < prevData.length; i++) {
              if (JSON.stringify(prevData[i]) !== JSON.stringify(successfulDiseases[i])) {
                return newData;
              }
            }
            return prevData;
          });
          setCategoriesData(successfulCategories);

          if (failedResponses.length > 0) {
            console.error(failedResponses);
          }
        } catch (error) {
          refreshFunction(error, fetchData, refreshToken, refresh, logout);
        } finally {
          setLoadingDiseasesData(false);
        }
      };
      fetchData();
    }
  }, [selectedDisease, diseasePageCap]);

  /*
  Creates arrays of therapeutic categories and diseases from API data.
  @param categoriesData {Object} The therapeutic categories data from the API
  @param diseasesData {Array} The diseases data from the API
  */
  let therapeuticCategories = [];
  let diseases = [];

  if (categoriesData) {
    therapeuticCategories =
      categoriesData.Therapeutic_categories &&
      categoriesData.Therapeutic_categories.map(
        (category) => `${category.Therapeutic_category_ID}|${category.Therapeutic_category_name}`,
      );
  }
  if (diseasesData) {
    diseases = diseasesData.map((disease) => `${disease.Disease_ID}|${disease.Disease_name}`);
  }

  /*
  Returns the count of a specific aggregation key from API data.
  @param key {String} The key for the aggregation data to retrieve
  @param activeButton {Number} The index of the currently active button
  @returns {Number|String} The count of the aggregation if it exists, 0 if the active button is 0, or '-' if the aggregation doesn't exist.
  */
  const getAggregationCount = (key) => {
    const aggregation = aggregationsData?.find((agg) => agg.key === key);
    return aggregation?.doc_count ? aggregation.doc_count : 0;
    // return aggregation?.doc_count ? aggregation.doc_count : activeButton === 0 ? 0 : '-';
  };

  // The array of button objects
  const buttons = [
    { key: 1, name: 'All', active: isActive, aggregation: null },
    {
      key: 2,
      name: 'Patents',
      active: isActive,
      aggregation: getAggregationCount('patents', activeButton),
    },
    {
      key: 3,
      name: 'Articles',
      active: isActive,
      aggregation: getAggregationCount('papers', activeButton),
    },
    {
      key: 4,
      name: 'Targets',
      active: isActive,
      aggregation: getAggregationCount('proteins', activeButton),
    },
    {
      key: 5,
      name: 'Financing',
      active: isActive,
      aggregation: getAggregationCount('finances', activeButton),
    },
  ];

  /*
  Handles a button click event.
  @param i {Number} The index of the button that was clicked
  */
  const handleButtonClick = (i) => {
    if (i === activeButton) return;

    let newIsActive = {};
    if (i === 0) {
      for (let j = 0; j < buttons.length; j++) {
        newIsActive[j] = true;
      }
    } else {
      for (let j = 0; j < buttons.length; j++) {
        newIsActive[j] = j === i;
      }
    }
    setIsActive(newIsActive);
    setActiveButton(i);
    setCurrentPage(1);
  };

  return (
    <div className='page'>
      {!globalSearchData ? (
        <LoadingSpinner page />
      ) : (
        <span className='page_container' style={{ gap: 16 }}>
          <div className='search_filter_container'>
            <SearchInput
              isSearchSystem
              minLength={3}
              placeholder='Search'
              allowClear
              value={newResultValue}
              onChange={handleOnChange}
              onSearch={onSearchClick}
              style={{
                maxWidth: 690,
                // height: 48,
                borderRadius: 8,
                backgroundColor: '#fff',
                boxShadow: '0px 2px 20px rgba(202, 202, 224, 0.12)',
              }}
              bordered={false}
              suffix={<span className='search_results_text'>{totalCount} results</span>}
              loading={loadingData}
            />
            <ButtonComp
              buttonClassName='text_btn_antd filter_btn'
              type='text'
              buttonIcon={isFilterShown ? <CloseFilterIcon /> : <OpenFilterIcon />}
              buttonSize='large'
              onButtonClick={showModal}
            >
              Filter
            </ButtonComp>
          </div>
          <span style={{ display: isFilterShown ? 'block' : 'none' }}>
            <FilteringSearchResultsComp
              loadingData={loadingDiseasesData}
              catValue={selectedCategory}
              onCatValue={handleCategoryChange}
              diseaseValue={selectedDisease}
              onDiseaseValue={handleDiseaseChange}
              therapyCat={filterCat.length > 0 ? filterCat : therapeuticCategories}
              disease={diseases}
              onDateChange={handleDateChange}
              searchVar={textSearchVar}
              onTextSearchVarChange={onTextSearchVarChange}
              isServerSideFunc
              handleSearch={handleSearch}
              handleLoadMore={handleLoadMore}
              searchValue={searchValue}
            />
          </span>
          <HorizontalList
            buttons={buttons}
            activeButton={activeButton}
            onClick={handleButtonClick}
            isGlobalSearch
          />
          {loadingData ? (
            <div className='results_container'>
              <span className='results_search_container'>
                <LoadingSpinner page />
              </span>
            </div>
          ) : !loadingData && totalCount && totalCount > 0 ? (
            <div className='results_container'>
              <span className='results_search_container'>
                {sortedData &&
                  sortedData.map((card) => {
                    let label = buttons[activeButton].name;
                    if (label.slice(-1) === 's') {
                      label = label.slice(0, -1);
                    }
                    if (isActive[0] || card.tag === label) {
                      return (
                        <ResultCard
                          key={card.id}
                          data={card}
                          selectedData={selectedData}
                          onDataClick={handleCardClick}
                        />
                      );
                    }
                  })}
                <div style={{ marginTop: 32 }}>
                  <Pagination
                    current={currentPage}
                    total={totalCount}
                    pageSize={itemsPerPage}
                    onChange={setCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </span>
              {selectedData && <ResultInfoCard data={selectedData} onDataClick={handleCardClick} />}
            </div>
          ) : (
            <div className='results_container'>
              <span className='results_search_container'>
                <span className='empty_container'>
                  <EmptyBoxIcon />
                  <span className='text_container'>
                    <span className='text_header'>No results were found for</span>
                    <span className='text'>{resultValue}</span>
                  </span>
                </span>
              </span>
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default GlobalSearchResults;
