import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingSpinner } from '../components/UI/LoadingComponent/LoadingComponent';

export const PrivateRoute = ({ user, children, redirectPath }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [user]);

  if (isLoading) {
    return <LoadingSpinner page />;
  }

  if (!user) {
    return <Navigate to={redirectPath ? redirectPath : '/login'} replace />;
  }

  return children ? children : <Outlet />;
};
