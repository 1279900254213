import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import { DatePickerComp } from '../UI/DatePickerComp/DatePickerComp';
import { DynamicTrendComp } from '../UI/DynamicTrendComp/DynamicTrendComp';
import { getTooltipIndexFields } from '../UI/CustomTooltip/tooltipData';
import { CustomTooltip } from '../UI/CustomTooltip/CustomTooltip';
import { LoadingSpinner } from '../UI/LoadingComponent/LoadingComponent';

import './index_chart.scss';
import { IndexTooltip } from './IndexTooltip';

/*
Component to display index chart and related data
@param indexData {Array} The data to be displayed on the chart
@param actualIndexData {Object} The actual index data to be displayed as a tooltip
@param onRangeChange {Function} The function to be called when date range is changed
@param loadingData {Boolean} A boolean flag to indicate if data is being loaded or not
@returns {JSX.Element}
*/
export const IndexChart = ({ indexData, actualIndexData, onRangeChange, loadingData }) => {
  // Helper function to format index values to 2 decimal places
  const formatIndex = (value) => {
    const formattedValue = Number((value * 100).toFixed(2));
    return Number.isInteger(formattedValue) ? `${formattedValue}.0` : formattedValue.toString();
  };

  // Format data for the chart and format index values
  const formattedData =
    indexData &&
    indexData.map((dat) => {
      const formattedIndex = formatIndex(dat.Relevant_index);
      return { ...dat, Relevant_index: formattedIndex };
    });

  const lastIndex = (formattedData && formattedData[formattedData.length - 1]) || {};
  const { Relevant_index: indexName, Last_change: lastChange = 0 } = lastIndex;
  const indexChange = parseFloat((lastChange * 100).toFixed(2));
  const index = Number.isInteger(indexChange) ? `${indexChange}.0` : indexChange.toString();

  // Destructure actual index data and format index values
  const {
    Relevant_index = 0,
    Probability_drug_approval = 0,
    Relevant_paper_index = 0,
    Relevant_patent_index = 0,
    Relevant_finance_index = 0,
  } = actualIndexData || {};

  const formattedActualIndex = formatIndex(Relevant_index);
  const formattedActualDrugprotIndex = formatIndex(Probability_drug_approval);
  const formattedActualPaperIndex = formatIndex(Relevant_paper_index);
  const formattedActualPatentIndex = formatIndex(Relevant_patent_index);
  const formattedActualFinancingIndex = formatIndex(Relevant_finance_index);

  // Index tooltip data to be displayed on hover
  const indexTooltipData = [
    { value: `${formattedActualIndex} = mean (`, label: 'index' },
    { value: `${formattedActualDrugprotIndex}, mean (`, label: 'clin trials prob' },
    { value: `${formattedActualPaperIndex},`, label: 'articles' },
    { value: `${formattedActualPatentIndex},`, label: 'patents' },
    { value: `${formattedActualFinancingIndex} ) )`, label: 'finances' },
    // { value: ')', label: '' },
  ];

  return (
    <span className='global_chart_container'>
      <span className='chart_index_title'>
        <span className='chart_index_data_container'>
          <span className='index_info_container'>
            <p className='chart_header'>Index</p>
            <IndexTooltip indexTooltipData={indexTooltipData} />
          </span>

          <span className='chart_index_container'>
            <p className='chart_index_data'>{indexName}</p>
            <DynamicTrendComp
              dynamicTrend={index > 0 ? 'up' : index < 0 ? 'down' : ''}
              dynamicTrendData={index}
            />
          </span>
        </span>
        <DatePickerComp isRange presets onChange={onRangeChange} placement='bottomRight' />
      </span>
      <div style={{ position: 'relative' }}>
        {loadingData && <LoadingSpinner />}
        <ResponsiveContainer width='100%' height={450}>
          <AreaChart
            data={formattedData}
            syncId='anyId'
            margin={{ top: 10, right: 0, left: -24, bottom: 0 }}
          >
            <CartesianGrid vertical={false} strokeDasharray='4' />
            <XAxis
              dataKey='Evaluation_date'
              interval='preserveStartEnd'
              tick={{ fontSize: 13, dy: 13 }}
            />
            <YAxis tickCount={11} domain={[0, 100]} />
            <Tooltip
              offset={20}
              wrapperStyle={{ outline: 'none' }}
              content={(props) => {
                const { payload } = props;
                if (payload && payload.length > 0) {
                  const selectedFields = getTooltipIndexFields(payload);
                  return <CustomTooltip isIndexChart data={selectedFields} />;
                }
                return null;
              }}
            />
            <Area
              type='linear'
              dataKey={'Relevant_index'}
              stroke='#52C41A'
              fill='rgba(82, 196, 26, 0.2)'
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </span>
  );
};
