import { Switch } from 'antd';
import './switch_comp.scss';

/*
 * Renders a switch button to toggle between displaying all the drugs or just the first one.
 * @param {Boolean} show - Determines whether to show all the drugs or just the first one.
 * @param {Function} setShow - Function to set the value of `show`.
 * @param {Array} data - An array of drugs.
 * @returns {JSX.Element} - The Switch component wrapped in a container.
 */
export const MedicineSwitchComp = ({ show, setShow, data }) => {
  return (
    <span className='switch_container'>
      <span>
        {data.length > 0 ? (show ? 'Show only the first drug' : 'Show all drugs') : 'No drugs'}
      </span>
      <Switch
        checked={data.length > 0 ? show : false}
        onChange={() => setShow(!show)}
        className='switch_box'
        disabled={data.length === 0}
      />
    </span>
  );
};
