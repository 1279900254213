import { debounce } from 'lodash';
/*
Wraps a function in a debounce function with the specified delay.
@param func {Function} The function to wrap in a debounce function.
@param delay {number} The delay in milliseconds to debounce the function.
@returns {Function} A debounced version of the original function.
*/
export const debounceFunction = (func, delay) => {
  const debouncedFunc = debounce(func, delay);
  return (value) => {
    debouncedFunc(value);
  };
};
