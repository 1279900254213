import { Descriptions } from 'antd';
// import { DownloadOutlined } from '@ant-design/icons';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg';
import './description.scss';
import { RenderRightArrayItem } from './RenderRightArrayItem';

/*
Renders the DescriptionTableComponent for the given props and options
@param {Object} props - The props to render the component
@param {Boolean} isTrends - A flag indicating if the component is for trends or not
@param {Boolean} isTarget - A flag indicating if the component is for target or not
@param {Number} activeButton - The active button index
@returns {JSX.Element}
*/
export const DescriptionTableComponent = (props, isTrends, isTarget, activeButton) => {
  return (
    <Descriptions className='description_container' layout='vertical' size='small'>
      {isTrends ? (
        <>
          <div style={{ width: '38%', paddingRight: '16px' }}>
            <div className='left_info_trends_container'>
              <span className='left_info_section'>
                <span className='info_text'>
                  Gene Symbols: {props.geneSymb || '-'}
                  {/* IDG Family: {props.geneSymb ? props.geneSymb : '-'} */}
                </span>
              </span>
              <span className='left_info_section'>
                <span className='info_text'>Patents: {props.patents || '-'}</span>
                <span className='info_text'>Articles: {props.papers || '-'}</span>
                <span className='info_text'>
                  Last change: {props.lastChange > 0 && '+'}
                  {parseFloat((props.lastChange * 100).toFixed(2)) || '-'}
                </span>
                {/* <span className='info_text'>Financing: {props.papers ? props.papers : '-'}</span> */}
              </span>
            </div>
          </div>
          <div style={{ width: '14%', padding: '0px 16px' }}>
            <div className='right_info_container'>
              <RenderRightArrayItem arrayItem={props.categories} collapse />
            </div>
          </div>
          <div style={{ width: '22%', paddingLeft: '8px' }}>
            <div className='right_info_container'>
              <RenderRightArrayItem arrayItem={props.diseases} collapse />
            </div>
          </div>
        </>
      ) : isTarget ? (
        <>
          {activeButton === 0 || activeButton === 1 ? (
            <div
              className='abstract_container'
              style={{
                width: activeButton === 0 ? '73%' : '67%',
              }}
            >
              <div className='left_info_container'>
                {activeButton === 0 ? (
                  <>
                    <div className='info_section'>
                      <span className='info_section_title'>Abstract</span>
                      <span className='info_section_text'>{props.abstract || '-'}</span>
                    </div>
                    <div className='buttons_section'>
                      <a
                        className={props.link ? 'source_link_global' : 'source_link_global_disable'}
                        href={props.link}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Read in source
                        <LinkIcon stroke={props.link ? '#cc33ff' : '#bab3bc'} />
                      </a>
                    </div>
                  </>
                ) : activeButton === 1 ? (
                  <>
                    <div className='info_section'>
                      <span className='info_section_title'>Abstract</span>
                      <span className='info_section_text'>
                        {props.abstract.replace(
                          /<\/?(?:(?!title)[^>])+>|<title>[^>]*<\/title>/g,
                          '',
                        ) || '-'}
                      </span>
                    </div>
                    <div className='buttons_section'>
                      {/* <ButtonComp
                      buttonClassName='btn_antd info_button_download'
                      type='text'
                      buttonIcon={<DownloadOutlined />}
                      buttonSize='large'
                      buttonDisabled={!props.Has_full_text_pdf}
                      onButtonClick={() => console.log('rex')}
                    >
                      Download PDF
                    </ButtonComp> */}
                      <a
                        className={props.DOI ? 'source_link_global' : 'source_link_global_disable'}
                        href={`https://dx.doi.org/${props.DOI}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Read in source
                        <LinkIcon stroke={props.DOI ? '#cc33ff' : '#bab3bc'} />
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : activeButton === 2 ? (
            <div className='abstract_container'>
              <div className='left_info_container'>
                <div className='info_section'>
                  <span className='info_section_text'>{props.abstract || '-'}</span>
                </div>
                <div className='buttons_section'>
                  <a
                    className={!props.link ? 'source_link_global_disable' : 'source_link_global'}
                    href={props.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Read in source
                    <LinkIcon stroke={props.link ? '#cc33ff' : '#bab3bc'} />
                  </a>
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 0 || activeButton === 1 ? (
            <div style={{ width: activeButton === 0 ? '25%' : '33%' }}>
              <div className='right_info_container'>
                {activeButton === 0 ? (
                  <>
                    <RenderRightArrayItem title='Assignees' arrayItem={props.assignees} />
                    <RenderRightArrayItem title='Inventors' arrayItem={props.inventors} />
                  </>
                ) : activeButton === 1 ? (
                  <>
                    <div className='info_section'>
                      <span className='info_section_title'>Journal</span>
                      <span className='info_section_text'>{props.journal || '-'}</span>
                    </div>
                    <RenderRightArrayItem title='Co-authors' arrayItem={props.co_authors} />
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Descriptions>
  );
};
