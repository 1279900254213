/*
This function first uses the reduce() method to sum up all the elements in the finArray.
It then uses the toLocaleString() method to format the sum as a currency value in US dollars with no decimal places. 
 */
const sumFinances = (finArray) => {
  const filteredArray = finArray.filter((value) => value < 1000000000);
  const sum = filteredArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const formattedSum = sum
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    })
    .replace(/,/g, ' ');
  return formattedSum;
};

/*
This function takes in a data object and parses its hits for patents, papers, targets, and finances.
It then extracts relevant data from each hit, such as title, date, and link, and adds it to the corresponding data array.
It then concatenates all data arrays, sorts the combined array by score, and returns the result.
@param data {Object} The data object containing hits to parse
@returns {Array} An array of sorted hits, containing information about patents, papers, targets, and finances
*/

export const getResultCard = (data) => {
  let patentsData = [];
  let papersData = [];
  let targetsData = [];
  let sortedData = [];

  if (data && !data.detail) {
    try {
      const searchHits = data.hits;
      for (let i = 0; i < searchHits.length; ++i) {
        const fullHitData = searchHits[i]?.source;

        const relationsArray = [];
        // if relations exist, add each relation's data to the relationsArray
        fullHitData.relations &&
          fullHitData.relations.forEach((relation) => {
            const uniprot_id = relation.protein.uniprot_id;
            const targetName = relation.protein.protein_name;
            const diseases = relation.diseases.map((disease) => {
              return `${disease.disease_name} ${disease.MESH}`;
            });
            relationsArray.push({ uniprot_id, targetName, diseases });
          });

        // sort the relationsArray in descending order based on the number of diseases
        relationsArray.sort((a, b) => {
          if (a.diseases && !b.diseases) {
            return -1;
          } else if (!a.diseases && b.diseases) {
            return 1;
          } else {
            return 0;
          }
        });

        // add relevant data to patentsData array if the hit is a patent
        if (searchHits[i].index === 'patents') {
          patentsData.push({
            key: searchHits[i].id,
            score: searchHits[i].score,
            id: searchHits[i].id,
            tag: 'Patent',
            title: fullHitData.title,
            applicationNumber: fullHitData.application_number,
            abstract: fullHitData.abstract,
            assignees: fullHitData.applicant,
            inventors: fullHitData.inventors,
            dateField: fullHitData.date_filed,
            dateIssue: fullHitData.date_issued,
            type: fullHitData.date_issued ? 'Patent' : 'Application',
            link: fullHitData.link,
            relations: relationsArray,
          });
        }

        // add relevant data to papersData array if the hit is a paper
        if (searchHits[i].index === 'papers') {
          papersData.push({
            key: searchHits[i].id,
            score: searchHits[i].score,
            id: searchHits[i].id,
            tag: 'Article',
            // title: fullHitData.title,
            title: fullHitData.title.replace(/<\/?(?:(?!title)[^>])+>|<title>[^>]*<\/title>/g, ''),
            DOI: fullHitData.doi,
            abstract: fullHitData.abstract.replace(
              /<\/?(?:(?!title)[^>])+>|<title>[^>]*<\/title>/g,
              '',
            ),
            author: fullHitData.author,
            coAuthors: fullHitData.co_authors,
            journal: fullHitData.journal_name,
            publicationDate: fullHitData.publication_date,
            relations: relationsArray,
          });
        }

        // add relevant data to proteinsData array if the hit is a protein
        if (searchHits[i].index === 'proteins') {
          targetsData.push({
            key: searchHits[i].id,
            score: searchHits[i].score,
            id: searchHits[i].id,
            tag: 'Target',
            title: fullHitData.protein_name,
            uniprotId: fullHitData.uniprot_id,
            geneSymbol: fullHitData.gene_name,
            patentsAmount: fullHitData.patents_amount ?? '',
            articlesAmount: fullHitData.papers_amount ?? '',
            financingAmount: fullHitData.financing_amount ?? '',
            categories: fullHitData.therapeutic_categories,
            diseases: fullHitData.diseases,
          });
        }

        // add relevant data to financesData array if the hit is a finance
        if (searchHits[i].index === 'finances') {
          targetsData.push({
            key: searchHits[i].id,
            score: searchHits[i].score,
            id: searchHits[i].id,
            tag: 'Financing',
            title: fullHitData.title,
            fullText: fullHitData.full_text,
            publicationDate: fullHitData.publication_date,
            link: fullHitData.link,
            amount: sumFinances(fullHitData.money_norm),
            company: fullHitData.crunchbase_companies,
          });
        }
      }
      //add arrays in new allData array
      const allData = [...patentsData, ...papersData, ...targetsData];
      //sort allData array by score from largest to smallest
      sortedData = allData.sort((a, b) => {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        return 0;
      });
    } catch (e) {
      console.error(e);
    }
  }
  return sortedData;
};
