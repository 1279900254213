import React from 'react';
import { Collapse, Col, Row } from 'antd';
import { DisplayData } from './DisplayFieldComp';
import './transition-comp.scss';
const { Panel } = Collapse;

/*
Display a collapsible panel with information about a gene, including gene names, sequence information, and metadata.
@param header {String} The header text to display for the panel.
@param description {Object} An object containing gene description data, including International nonproprietary names, Name of CD antigens, Alternative names, Recommended names, Submission names, Allergen name, Secondary accessions, and Keywords.
@param gene {String} The name of the gene.
@returns {JSX.Element}
*/
export const TransitionExpandComp = ({ header, description, gene }) => {
  const {
    Description: descriptionData,
    Sequence: sequenceData,
    Entry_audit: entryAuditData,
  } = description || {};
  const secondaryAccessions = description && description.Secondary_accessions;
  const keywords = description && description.Keywords;

  // Define an array of objects, each containing a title and text to display in a row for the gene's information.
  const rows = [
    {
      title: 'International nonproprietary names',
      text: descriptionData && descriptionData.inn_names,
    },
    { title: 'Name of CD antigens', text: descriptionData && descriptionData.cd_antigen_names },
    { title: 'Alternative names', text: descriptionData && descriptionData.alternative_names },
    { title: 'Recommended names', text: descriptionData && descriptionData.recommended_name },
    { title: 'Submission names', text: descriptionData && descriptionData.submission_names },
    { title: 'Allergen name', text: descriptionData && descriptionData.allergen_name },
    { title: 'Gene name', text: gene },
    { title: 'Secondary accessions', text: secondaryAccessions },
    { title: 'Sequence', text: sequenceData && sequenceData.sequence },
    { title: 'Length', text: sequenceData && sequenceData.length },
    { title: 'Molecular weight', text: sequenceData && sequenceData.mol_weight },
    // { title: 'MD5', text: sequenceData && sequenceData.md5 },
    { title: 'Sequence version', text: entryAuditData && entryAuditData.sequence_version },
    {
      title: 'Last sequence update date',
      text: entryAuditData && entryAuditData.last_sequence_update_date,
    },
    { title: 'First public date', text: entryAuditData && entryAuditData.first_public_date },
    { title: 'Keywords', text: keywords },
  ];

  // Render the collapsible panel with the rows of information displayed.
  return (
    <Collapse className='transition_parent' collapsible='header' bordered={false}>
      <Panel header={header} key='1' className='header_class'>
        <span className='description_container'>
          {rows &&
            rows.map((row, index) => (
              <Row gutter={16} key={index}>
                <Col className='title_row' span={5}>
                  <div>{row.title}</div>
                </Col>
                <Col className='description_row' span={19}>
                  <DisplayData text={row.text} />
                </Col>
              </Row>
            ))}
        </span>
      </Panel>
    </Collapse>
  );
};
