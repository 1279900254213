/*
Get the minimum and maximum values of a specific field in an array of objects
@param array {Array} The array of objects to search through
@param field {String} The name of the field to search for the minimum and maximum values
@returns {Array} An array containing the minimum and maximum values of the specified field
*/
export const getMinMax = (array, field) => {
  const [minYear, maxYear] = array.reduce(
    (acc, obj) => {
      const newField =
        typeof obj[field] === 'number' ? obj[field] : new Date(obj[field]).getFullYear();

      return [Math.min(acc[0], newField), Math.max(acc[1], newField)];
    },
    [Infinity, -Infinity],
  );
  return [minYear, maxYear];
};
