import { Link } from 'react-router-dom';
import { DynamicTrendComp } from '../../components/UI/DynamicTrendComp/DynamicTrendComp';

//get Trends table columns
export const trendsColumns = () => [
  {
    title: 'Trend index',
    dataIndex: 'trendIndex',
    key: 'trendIndex',
    render: (text) => {
      const lastChange = parseFloat(text && text.split('|')[1]);
      let relevantIndex = parseFloat(text && text.split('|')[0]);
      if (relevantIndex < 1) {
        relevantIndex *= 100;
      }
      relevantIndex = parseFloat(relevantIndex.toFixed(2));
      const displayedRelevantIndex =
        relevantIndex % 1 === 0 ? `${relevantIndex}.0` : relevantIndex.toString();
      return (
        <span className='table_trend_index'>
          <DynamicTrendComp dynamicTrend={lastChange > 0 ? 'up' : lastChange < 0 ? 'down' : ''} />
          {displayedRelevantIndex}
        </span>
      );
    },
    width: 120,
    overflow: 'wrap',
    sorter: (a, b) => {
      const aLastChange = parseFloat(a.trendIndex.split('|')[1]);
      const bLastChange = parseFloat(b.trendIndex.split('|')[1]);
      return aLastChange - bLastChange;
    },
  },
  {
    title: 'Uniprot ID',
    dataIndex: 'uniprotID',
    key: 'uniprotID',
    render: (text, record) => (
      <Link className='table_header_link' to={`target/${record.uniprotID}`}>
        {text}
      </Link>
    ),
    width: 150,
    overflow: 'wrap',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 300,
    overflow: 'wrap',
  },
  {
    title: 'Therapeutic category',
    dataIndex: 'therapyCat',
    key: 'therapyCat',
    width: 200,
    overflow: 'wrap',
    sorter: (a, b) => a.therapyCat - b.therapyCat,
  },
  {
    title: 'Disease',
    dataIndex: 'disease',
    key: 'disease',
    width: 200,
    overflow: 'wrap',
    sorter: (a, b) => a.disease - b.disease,
  },
  {
    title: 'Last mention',
    dataIndex: 'lastMention',
    key: 'lastMention',
    width: 140,
    overflow: 'wrap',
    sorter: (a, b) => {
      const dateA = new Date(a.lastMention);
      const dateB = new Date(b.lastMention);
      return dateA - dateB;
    },
  },
];
